import React, { useState } from 'react';
import { ico_eye_slash, ico_eye, ico_key } from '../../icon';
import Stylesheet from './Password.module.css';

interface PasswordProps {
    icon?: JSX.Element | boolean,
    onChange?: (value: string) => void,
    onBlur?: (value: string) => void,
    placeholder?: string,
    defaultValue?: string,
    required?: boolean,
    disabled?: boolean
}

const Password = (props: PasswordProps) => {
    const [show, setShow] = useState(false);
    return (
        <div className={Stylesheet.Container}>
            {props?.icon
            ? <h5 className={Stylesheet.Icon}>
            {typeof props.icon === "boolean" 
            ? ico_key : props.icon}</h5>
            : null}
            <input 
            onChange={event => props?.onChange && props.onChange(event.target.value)}
            onBlur={event => props?.onBlur && props.onBlur(event.target.value)}
            placeholder={props?.placeholder || 'password'}
            type={show ? "text" : "password"}
            defaultValue={props.defaultValue || ""}
            required={props?.required || false} 
            disabled={props.disabled} />
            <button type="button"
            onClick={_ => setShow(show ? false : true)}>
            {show ? ico_eye : ico_eye_slash}
            </button>
        </div>
    )
}

export default Password;