import {show, MessageBoxState, MessageBoxResponses} from '../reducers/messageBox';
import store from '../index';

export const toggleMessageBox = async (action: MessageBoxState): Promise<MessageBoxResponses | string> => {
    store.dispatch(show(action));
    return new Promise(
        (resolve, reject) => {
            const unsub = store.subscribe(() => {
                const mesageBoxSlice = store.getState().messageBox;
                if (mesageBoxSlice.response) {
                    resolve(mesageBoxSlice.response);
                    unsub();
                }
            })
        }
    )
}