import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

// Define a type for the slice state
export interface ModalState {
    show?: boolean,
    type?: "settings"
}
// Define the initial state using that type
const initialState: ModalState = {
    show: false
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<ModalState>) => {
            return { ...action.payload, show: true };
        },
        hide: state => {
            return { ...state, show: false };
        }
    }
})

export const { show, hide } = modalSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectModel = (state: RootState) => state.modal;

export default modalSlice.reducer;