import React from 'react';
import Image from '../../elements/Image/Image';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Stylesheet from './Menu.module.css';
import DefaultUser from '../../assets/user-default.jpg';
import { Link } from 'react-router-dom';
import { ico_book, ico_cog, ico_house_user, ico_sign_out_alt, ico_user, ico_users } from '../../icon';
import Classnames from 'classnames';
import Button from '../../elements/Button/Button';
import { user as auth } from '@enigma/core/services'
import { toggleMenu } from '../../store/reducers/app';

const Menu = () => {
    const user = useAppSelector(state => state.users);
    const app = useAppSelector(state => state.app);
    const dispatch = useAppDispatch();

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: app.toggleMenu,
        [Stylesheet['hide-container']]: !app.toggleMenu
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: app.toggleMenu,
        [Stylesheet['hide-overlay']]: !app.toggleMenu
    })

    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: app.toggleMenu,
        [Stylesheet['hide-popup']]: !app.toggleMenu
    })

    return (
        <div className={containerStyle} >
            <div className={overlayStyle}
                onClick={_ => dispatch(toggleMenu())}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Photo}>
                    {!user?.providerID
                        ? <Image
                            src={user.photoURLLarge || DefaultUser}
                            alt='user profile' />
                        : <Image
                            src={user.photoURLLarge || DefaultUser}
                            alt='user profile' />}
                </div>
                <div className={Stylesheet.Metadata}>
                    <h3>{user.displayName}</h3>
                    <h4>{user.email}</h4>
                    <h4>{user.role} account</h4>
                    <Button secondary onClick={_ => auth.signOut()}>{ico_sign_out_alt}&emsp;log out</Button>
                </div>
                <div className={Stylesheet.Tabs}>
                    <div className={Stylesheet.Tab}>
                        <Link to='/'>
                            <p>{ico_house_user}&emsp;home</p>
                        </Link>
                    </div>
                    <div className={Stylesheet.Tab}>
                        <Link to='/profile/about'>
                            <p>{ico_user}&emsp;about</p>
                        </Link>
                    </div>
                    {user.role !== "user"
                        ? <div className={Stylesheet.Tab}>
                            <Link to='/profile/templates'>
                                <p>{ico_book}&emsp;templates</p>
                            </Link>
                        </div>
                        : null}
                    {user.role === "administrator" || user.role === "root"
                        ? <div className={Stylesheet.Tab}>
                            <Link to='/profile/adminstration'>
                                <p>{ico_users}&emsp;adminstration</p>
                            </Link>
                        </div>
                        : null}
                    <div className={Stylesheet.Tab}>
                        <Link to='/profile/settings'>
                            <p>{ico_cog}&emsp;settings</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Menu;