import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';
import { SourceInterface } from './editor';


interface PreviewerSettings {
    language: Array<"EN" | "GR" | "AR">,
    jurisdiction: "AANA" | "GOA" | "OCA"
}

interface PreviewerState {
    fontSize?: number,
    settings: PreviewerSettings,
    activeSources?: Array<SourceInterface>
}

// Define the initial state using that type
const initialState: PreviewerState = {
    settings: {
        language: ["EN"],
        jurisdiction: "AANA"
    }
}

export const previewerSlice = createSlice({
    name: 'previewer',
    initialState,
    reducers: {
        setFontSize: (state, action: PayloadAction<number>) => {
            return { ...state, fontSize: action.payload };
        },
        setActiveSources: (state, action: PayloadAction<Array<SourceInterface>>) => {
            return { ...state, activeSources: action.payload };
        },
        setSettings: (state, action: PayloadAction<PreviewerSettings>) => {
            return { ...state, settings: action.payload }
        },
        setLanguages: (state, action: PayloadAction<Array<"EN" | "GR" | "AR">>) => {
            return { ...state, settings: { ...state.settings, language: action.payload } }
        }
    }
})

export const {
    setFontSize,
    setSettings,
    setActiveSources
} = previewerSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectPreviewer = (state: RootState) => state.previewer;

export default previewerSlice.reducer