import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import Stylesheet from './Adminstration.module.css';
import { Navigate } from 'react-router-dom';
import toggleToast from '../../../store/actions/toast.actions';
import { UserState } from '../../../store/reducers/users';
import { firestore } from '@enigma/core/services';
import { ico_search, ico_user_slash } from '../../../icon';
import User from '../../../components/User/User';
import Search from '../../../elements/Search/Search';
import { formatAddress } from '../../../elements/Address/Address';

const Adminstration = () => {
    const userMeta = useAppSelector(state => state.users);
    const [users, setUsers] = useState<Array<UserState>>([])
    const [result, setResult] = useState<Array<UserState> | null>();
    // TEMP CODE
    useEffect(() => {
        if (!userMeta.email) return;
        // fetch all users from collection
        if (userMeta.role === "root")
            firestore.collection('users')
                .where("role", "!=", "root")
                .onSnapshot(snap => {
                    let collection: Array<UserState> = [];
                    for (const doc of snap.docs)
                        collection.push({ ...doc.data(), id: doc.id });
                    setUsers(collection);
                })
        else
            firestore.collection('users')
                .where("email", "!=", userMeta.email)
                .onSnapshot(snap => {
                    let collection: Array<UserState> = [];
                    for (const doc of snap.docs)
                        if (doc.data().role !== "root")
                            collection.push({ ...doc.data(), id: doc.id });
                    setUsers(collection);
                })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const collection = () => {
        if (users.length === 0) return (
            <div className={Stylesheet.NoUsers}>
                <h1>{ico_user_slash}</h1>
                <h3>no other users to show</h3>
                <h4>
                    To refine your search be as specific as possible, as partial matches
                    are also considered during filtering, the users display name, email
                    address and provided residential address are only searched.
                </h4>
            </div>
        )
        if (result) {
            if (result.length > 0)
                return (
                    <React.Fragment>
                        {result.map((user, index) => (
                            <User {...users.find(({ id }) => id === user.id)}
                                key={index + Math.random()} />
                        ))}
                    </React.Fragment>
                )
            return (
                <div className={Stylesheet.NoUsers}>
                    <h1>{ico_search}</h1>
                    <h3>no users match your search</h3>
                    <h4>
                        To refine your search be as specific as possible, as partial matches
                        are also considered during filtering, the users display name, email
                        address and provided residential address are only searched.
                    </h4>
                </div>
            )
        }
        return (
            <React.Fragment>
                {users.map((user, index) => (
                    <User {...user} key={index + Math.random()} />
                ))}
            </React.Fragment>
        )
    }

    const filter = (search: string) => {
        search = search.toLocaleLowerCase()
        if (search !== "")
            setResult(users.filter((current) => {
                if (current.email?.match(search)
                    || current.displayName?.toLocaleLowerCase().match(search)
                    || (current.address && formatAddress(current.address).toLocaleLowerCase().match(search)))
                    return true;
                return false;
            }))
        else setResult(null);
    }


    if (userMeta.role !== "administrator" && userMeta.role !== "root") {
        toggleToast({
            message: "Oops, you're not authorized to view this page",
            isError: true
        });
        return <Navigate to='/profile/about' />
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Search}>
                <Search onChange={value => filter(value)}
                    placeholder='search display name, email or by user address' />
            </div>
            <div className={Stylesheet.UserListing}>
                {collection()}
            </div>
        </div>
    )
}

export default Adminstration;