import React from 'react';
//JSX icon fonts
export const ico_question = <span><i className="fas fa-question"></i></span>
export const ico_cogs = <span><i className="fas fa-cogs"></i></span>
export const ico_user_check = <span><i className="fas fa-user-check"></i></span>
export const ico_search = <span><i className="fas fa-search"></i></span>
export const ico_toolbox = <span><i className="fas fa-toolbox"></i></span>
export const ico_bars = <span><i className="fas fa-bars"></i></span>
export const ico_moon = <span><i className="fas fa-moon"></i></span>
export const ico_text_height = <span><i className="fas fa-text-height"></i></span>
export const ico_sun = <span><i className="fas fa-sun"></i></span>
export const ico_envelope = <span><i className="fas fa-envelope"></i></span>
export const ico_phone = <span><i className="fas fa-phone"></i></span>
export const ico_thumbs_up = <span><i className="fas fa-thumbs-up"></i></span>
export const ico_ad = <span><i className="fas fa-ad"></i></span>
export const ico_at = <span><i className="fas fa-at"></i></span>
export const ico_paint_brush = <span><i className="fas fa-paint-brush"></i></span>
export const ico_cog = <span><i className="fas fa-cog"></i></span>
export const ico_android = <span><i className="fab fa-android"></i></span>
export const ico_apple = <span><i className="fab fa-apple"></i></span>
export const ico_code = <span><i className="fas fa-code"></i></span>
export const ico_react = <span><i className="fab fa-react"></i></span>
export const ico_microsoft = <span><i className="fab fa-microsoft"></i></span>
export const ico_linux = <span><i className="fab fa-linux"></i></span>
export const ico_database = <span><i className="fas fa-database"></i></span>
export const ico_globe_americas = <span><i className="fas fa-globe-americas"></i></span>
export const ico_folder = <span><i className="fas fa-folder"></i></span>
export const ico_user_friends = <span><i className="fas fa-user-friends"></i></span>
export const ico_paper_plane = <span><i className="fas fa-paper-plane"></i></span>
export const ico_terminal = <span><i className="fas fa-terminal"></i></span>
export const ico_robot = <span><i className="fas fa-robot"></i></span>
export const ico_user_clock = <span><i className="fas fa-user-clock"></i></span>
export const ico_bolt = <span><i className="fas fa-bolt"></i></span>
export const ico_instagram = <span><i className="fab fa-instagram"></i></span>
export const ico_twitter = <span><i className="fab fa-twitter"></i></span>
export const ico_linked_in = <span><i className="fab fa-linkedin-in"></i></span>
export const ico_font = <span><i className="fas fa-font"></i></span>
export const ico_magic = <span><i className="fas fa-magic"></i></span>
export const ico_camera_retro = <span><i className="fas fa-camera-retro"></i></span>
export const ico_times_circle = <span><i className="fas fa-times-circle"></i></span>
export const ico_times = <span><i className="fas fa-times"></i></span>
export const ico_play = <span><i className="fas fa-play"></i></span>
export const ico_pause = <span><i className="fas fa-pause"></i></span>
export const ico_shopping_cart = <span><i className="fas fa-shopping-cart"></i></span>
export const ico_sort_up = <span><i className="fas fa-sort-up"></i></span>
export const ico_book = <span><i className="fas fa-book"></i></span>
export const ico_book_reader = <span><i className="fas fa-book-reader"></i></span>
export const ico_frown = <span><i className="far fa-frown"></i></span>
export const ico_poo = <span><i className="fas fa-poo"></i></span>
export const ico_chalkboard_teacher = <span><i className="fas fa-chalkboard-teacher"></i></span>
export const ico_microchip = <span><i className="fas fa-microchip"></i></span>
export const ico_money_check = <span><i className="fas fa-money-check-alt"></i></span>
export const ico_chart_line = <span><i className="fas fa-chart-line"></i></span>
export const ico_smile_beam = <span><i className="fas fa-smile-beam"></i></span>
export const ico_smail_wink = <span><i className="fas fa-smile-wink"></i></span>
export const ico_unlock = <span><i className="fas fa-unlock"></i></span>
export const ico_bomb = <span><i className="fas fa-bomb"></i></span>
export const ico_laugh_squint = <span><i className="fas fa-laugh-squint"></i></span>
export const ico_surpries = <span><i className="fas fa-surprise"></i></span>
export const ico_envelope_open_text = <span><i className="fas fa-envelope-open-text"></i></span>
export const ico_check = <span><i className="fas fa-check"></i></span>
export const ico_caret_down = <span><i className="fas fa-caret-down"></i></span>
export const ico_circle = <span><i className="fas fa-circle"></i></span>
export const ico_user = <span><i className="fas fa-user"></i></span>
export const ico_users = <span><i className="fas fa-users"></i></span>
export const ico_exclamation_triangle = <span><i className="fas fa-exclamation-triangle"></i></span>
export const ico_mars = <span><i className="fas fa-mars"></i></span>
export const ico_venus = <span><i className="fas fa-venus"></i></span>
export const ico_birthday_cake = <span><i className="fas fa-birthday-cake"></i></span>
export const ico_user_secret = <span><i className="fas fa-user-secret"></i></span>
export const ico_user_tie = <span><i className="fas fa-user-tie"></i></span>
export const ico_building = <span><i className="fas fa-building"></i></span>
export const ico_map_marked_alt = <span><i className="fas fa-map-marked-alt"></i></span>
export const ico_globe_asia = <span><i className="fas fa-globe-asia"></i></span>
export const ico_graduation_cap = <span><i className="fas fa-graduation-cap"></i></span>
export const ico_far_building = <span><i className="far fa-building"></i></span>
export const ico_school = <span><i className="fas fa-school"></i></span>
export const ico_far_smile_beam = <span><i className="far fa-smile-beam"></i></span>
export const ico_bell = <span><i className="fas fa-bell"></i></span>
export const ico_sign_out_alt = <span><i className="fas fa-sign-out-alt"></i></span>
export const ico_receipt = <span><i className="fas fa-receipt"></i></span>
export const ico_user_cog = <span><i className="fas fa-user-cog"></i></span>
export const ico_comments = <span><i className="fas fa-comments"></i></span>
export const ico_user_lock = <span><i className="fas fa-user-lock"></i></span>
export const ico_folder_open = <span><i className="fas fa-folder-open"></i></span>
export const ico_history = <span><i className="fas fa-history"></i></span>
export const ico_open_book = <span><i className="fas fa-book-open"></i></span>
export const ico_trophy = <span><i className="fas fa-trophy"></i></span>
export const ico_cart_plus = <span><i className="fas fa-cart-plus"></i></span>
export const ico_bookmark = <span><i className="fas fa-bookmark"></i></span>
export const ico_stopwatch = <span><i className="fas fa-stopwatch"></i></span>
export const ico_star = <span><i className="fas fa-star"></i></span>
export const ico_gavel = <span><i className="fas fa-gavel"></i></span>
export const ico_plus = <span><i className="fas fa-plus"></i></span> 
export const ico_cubes = <span><i className="fas fa-cubes"></i></span> 
export const ico_align_justify = <span><i className="fas fa-align-justify"></i></span> 
export const ico_pen_alt = <span><i className="fas fa-pen-alt"></i></span>
export const ico_trash = <span><i className="fas fa-trash"></i></span>
export const ico_key = <span><i className="fas fa-key"></i></span>
export const ico_user_slash = <span><i className="fas fa-user-slash"></i></span>
export const ico_comment_alt = <span><i className="fas fa-comment-alt"></i></span>
export const ico_handshake = <span><i className="fas fa-handshake"></i></span>
export const ico_eye_slash = <span><i className="fas fa-eye-slash"></i></span>
export const ico_file_video_fas = <span><i className="fas fa-file-video"></i></span>
export const ico_file_download = <span><i className="fas fa-file-download"></i></span>
export const ico_link = <span><i className="fas fa-link"></i></span>
export const ico_file_word = <span><i className="far fa-file-word"></i></span>
export const ico_file_word_fas = <span><i className="fas fa-file-word"></i></span>
export const ico_file_powerpoint = <span><i className="far fa-file-powerpoint"></i></span>
export const ico_file_powerpoint_fas = <span><i className="fas fa-file-powerpoint"></i></span>
export const ico_file_pdf = <span><i className="far fa-file-pdf"></i></span>
export const ico_file_pdf_fas = <span><i className="fas fa-file-pdf"></i></span>
export const ico_file = <span><i className="far fa-file"></i></span>
export const ico_file_upload = <span><i className="fas fa-file-upload"></i></span>
export const ico_upload = <span><i className="fas fa-upload"></i></span>
export const ico_check_circle = <span><i className="fas fa-check-circle"></i></span>
export const ico_techometer_alt = <span><i className="fas fa-tachometer-alt"></i></span>
export const ico_plus_square = <span><i className="fas fa-plus-square"></i></span>
export const ico_copy = <span><i className="fas fa-copy"></i></span>
export const ico_child = <span><i className="fas fa-child"></i></span>
export const ico_arrow_left = <span><i className="fas fa-arrow-left"></i></span>
export const ico_arrow_right = <span><i className="fas fa-arrow-right"></i></span>
export const ico_thumbtack = <span><i className="fas fa-thumbtack"></i></span>
export const ico_smile = <span><i className="far fa-smile"></i></span>
export const ico_sort_numeric_up = <span><i className="fas fa-sort-numeric-up"></i></span>
export const ico_sort_numeric_down = <span><i className="fas fa-sort-numeric-down"></i></span>
export const ico_sort_alpha_up = <span><i className="fas fa-sort-alpha-up"></i></span>
export const ico_sort_alpha_down = <span><i className="fas fa-sort-alpha-down"></i></span>
export const ico_save = <span><i className="fas fa-save"></i></span>
export const ico_folder_plus = <span><i className="fas fa-folder-plus"></i></span>
export const ico_list = <span><i className="fas fa-list"></i></span>
export const ico_th = <span><i className="fas fa-th"></i></span>
export const ico_file_fa = <span><i className="fas fa-file"></i></span>
export const ico_file_alt = <span><i className="fas fa-file-alt"></i></span>
export const ico_file_excel = <span><i className="fas fa-file-excel"></i></span>
export const ico_file_code_fas = <span><i className="fas fa-file-code"></i></span>
export const ico_file_code = <span><i className="far fa-file-code"></i></span>
export const ico_file_csv = <span><i className="fas fa-file-csv"></i></span>
export const ico_map_marker = <span><i className="fas fa-map-marker"></i></span>
export const ico_language = <span><i className="fas fa-language"></i></span>
export const ico_credit_card = <span><i className="fas fa-credit-card"></i></span>
export const ico_question_circle = <span><i className="fas fa-question-circle"></i></span>
export const ico_info_circle = <span><i className="fas fa-info-circle"></i></span>
export const ico_flag = <span><i className="fas fa-flag"></i></span>
export const ico_lock = <span><i className="fas fa-lock"></i></span>
export const ico_redo_alt = <span><i className="fas fa-redo-alt"></i></span>
export const ico_volume_mute = <span><i className="fas fa-volume-mute"></i></span>
export const ico_volume_up = <span><i className="fas fa-volume-up"></i></span>
export const ico_volume_down = <span><i className="fas fa-volume-down"></i></span>
export const ico_expand_arrows_alt = <span><i className="fas fa-expand-arrows-alt"></i></span>
export const ico_compress_arrows_alt = <span><i className="fas fa-compress-arrows-alt"></i></span>
export const ico_step_forward = <span><i className="fas fa-step-forward"></i></span>
export const ico_closed_captioning = <span><i className="fas fa-closed-captioning"></i></span>
export const ico_infinity = <span><i className="fas fa-infinity"></i></span>
export const ico_clipboard_check = <span><i className="fas fa-clipboard-check"></i></span>
export const ico_cc_visa = <span><i className="fab fa-cc-visa"></i></span>
export const ico_cc_paypal = <span><i className="fab fa-cc-paypal"></i></span>
export const ico_cc_mastercard = <span><i className="fab fa-cc-mastercard"></i></span>
export const ico_cc_jcb = <span><i className="fab fa-cc-jcb"></i></span>
export const ico_cc_discover = <span><i className="fab fa-cc-discover"></i></span>
export const ico_cc_apple_pay = <span><i className="fab fa-cc-apple-pay"></i></span>
export const ico_cc_amex = <span><i className="fab fa-cc-amex"></i></span>
export const ico_cc_amazon_pay = <span><i className="fab fa-cc-amazon-pay"></i></span>
export const ico_cash_register = <span><i className="fas fa-cash-register"></i></span>
export const ico_spinner = <span><i className="fas fa-spinner"></i></span>
export const ico_ellipsis_v = <span><i className="fas fa-ellipsis-v"></i></span>
export const ico_user_plus = <span><i className="fas fa-user-plus"></i></span>
export const ico_user_graduate = <span><i className="fas fa-user-graduate"></i></span>
export const ico_eye = <span><i className="fas fa-eye"></i></span>
export const ico_calendar_alt = <span><i className="far fa-calendar-alt"></i></span>
export const ico_crop_alt = <span><i className="fas fa-crop-alt"></i></span>
export const ico_inbox = <span><i className="fas fa-inbox"></i></span>
export const ico_house_user = <span><i className="fas fa-house-user"></i></span>
export const ico_wallet = <span><i className="fas fa-wallet"></i></span>
export const ico_file_invoice_dollar = <span><i className="fas fa-file-invoice-dollar"></i></span>
export const ico_crown = <span><i className="fas fa-crown"></i></span>
export const ico_chess_king = <span><i className="fas fa-chess-king"></i></span>
export const ico_chess_knight = <span><i className="fas fa-chess-knight"></i></span>
export const ico_chess_rook = <span><i className="fas fa-chess-rook"></i></span>
export const ico_filter = <span><i className="fas fa-filter"></i></span>
export const ico_heart = <span><i className="far fa-heart"></i></span>
export const ico_hourglass_start = <span><i className="fas fa-hourglass-start"></i></span>
export const ico_sort_down = <span><i className="fas fa-sort-down"></i></span>
export const ico_table = <span><i className="fas fa-table"></i></span>
export const ico_file_medical_alt = <span><i className="fas fa-file-medical-alt"></i></span>
export const ico_sliders = <span><i className="fas fa-sliders-h"></i></span>
export const ico_angle_down = <span><i className="fas fa-angle-down"></i></span>
export const ico_cloud = <span><i className="fas fa-cloud"></i></span>
export const ico_clock = <span><i className="fas fa-clock"></i></span>
export const ico_map_marker_alt = <span><i className="fas fa-map-marker-alt"></i></span>
export const ico_bell_slash = <span><i className="fas fa-bell-slash"></i></span>
export const ico_sync = <span><i className="fas fa-sync"></i></span>
export const ico_google = <span><i className="fab fa-google"></i></span>
export const ico_facebook = <span><i className="fab fa-facebook"></i></span>
export const ico_user_edit = <span><i className="fas fa-user-edit"></i></span>
export const ico_bug = <span><i className="fas fa-bug"></i></span>
export const ico_js = <span><i className="fab fa-js-square"></i></span>
export const ico_css = <span><i className="fab fa-css3-alt"></i></span>
export const ico_html = <span><i className="fab fa-html5"></i></span>
export const ico_print = <span><i className="fas fa-print"></i></span>
export const ico_share_alt = <span><i className="fas fa-share-alt"></i></span>
export const ico_house_blank = <span><i className="fa-solid fa-house-blank"></i></span>
export const ico_message = <span><i className="fa-solid fa-message"></i></span>