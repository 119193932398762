import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

type AuthProviders = "facebook" | "google";
// Define a type for the slice state
interface AuthState {
    title?: string,
    type?: "sign-up" | "sign-in",
    providers?: Array<AuthProviders>
    show: boolean
}
// Define the initial state using that type
const initialState: AuthState = {
    show: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<AuthState>) => {
            return { ...action.payload, show: true };
        },
        close: state => {
            return { ...state, show: false };
        }
    }
})

export const { show, close } = authSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;