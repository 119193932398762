import React, { useState } from 'react';
import Address from '../../../elements/Address/Address';
import Button from '../../../elements/Button/Button';
import Datepicker from '../../../elements/Datepicker/Datepicker';
import Password from '../../../elements/Password/Password';
import Phone from '../../../elements/Phone/Phone';
import { toggleMessageBox } from '../../../store/actions/messageBox.actions';
import Stylesheet from './Settings.module.css';
import { user } from '@enigma/core/services';
import toggleToast from '../../../store/actions/toast.actions';
import { useAppSelector } from '../../../store/hooks';
import Email from '../../../elements/Email/Email';
import { ico_pen_alt } from '../../../icon';
import Input from '../../../elements/Input/Input';
import { MessageBoxTypes } from '../../../store/reducers/messageBox';


const Settings = () => {
    const userMeta = useAppSelector(state => state.users);
    const [editProfile, setEditProfile] = useState(false);

    const updateLangues = (lang: string) => {
        const foundIndex = userMeta.languages?.findIndex((value) => value === lang);
        if (userMeta.languages?.length === 2 && foundIndex === -1) {
            toggleToast({ isError: true, message: "only 2 languages are allowed at a time" });
            return;
        }
        // add to list of languages
        if (foundIndex === -1) user.setProfile({ languages: [...userMeta?.languages || [], lang] })
        // remove from list of languages
        else user.setProfile({ languages: userMeta.languages?.filter((value) => value !== lang) || [lang] })
    }

    const resendEmailVerification = () => {
        user.sendEmailVerification()
        .then(_ => {
            toggleToast({
                message: "verification email sent, please check your inbox for further instructions.",
                isError: false
            })
        })
    }

    const updateEmailAddress = async (newEmail: string) => {
        if (newEmail === userMeta.email) return;
        if (!await user.isEmailAvailable(newEmail, ["facebook.com", "google.com", "password"])) {
            toggleToast({ isError: true, message: "email address is already in use" });
            return;
        }
        const response = await toggleMessageBox({ title: "update email address", type: MessageBoxTypes.authenticate });
        if (response === "authenticated") {
            user.updateEmail(newEmail)
                .then(async _ => {
                    await user.setProfile({ email: newEmail });
                    toggleToast({ isError: false, message: "email address updated, please verify the new email address" });
                })
                .catch(_ => { toggleToast({ isError: true, message: "authentication was not successful, please try again later." }) })
        }
    }

    const updatePassword = async (newPassword: string) => {
        if (newPassword === "") return;
        const response = await toggleMessageBox({ title: "update password", type: MessageBoxTypes.authenticate });
        if (response === "authenticated") {
            user.updatePassword(newPassword)
                .then(async _ => {
                    toggleToast({
                        isError: false,
                        message: "you're password has been changed."
                    });
                })
                .catch(_ => {
                    // re-authenticate user
                    toggleToast({
                        isError: true,
                        message: "authentication was not successful, please try again later."
                    });
                })
        }
    }

    const signout = async () => {
        const response = await toggleMessageBox({
            title: "confirm sign-out",
            prompt: "This operation will log you out of all services, are you sure you wish to logout ?",
            type: MessageBoxTypes.yes_no
        });

        if (response === "yes") user.signOut();
    }

    return (
        <div className={Stylesheet.Continer}>
            <div className={Stylesheet.Field}>
                <div className={Stylesheet.Meta}>
                    <h5>jurisdiction</h5>
                    <h6>This affects the structure of services</h6>
                </div>
                <div className={Stylesheet.Toggles}>
                    <Button primary={userMeta.jurisdiction === "AANA"}
                        onClick={_ => user.setProfile({ jurisdiction: "AANA" })}>AANA</Button>
                    <Button primary={userMeta.jurisdiction === "GOA"}
                        onClick={_ => user.setProfile({ jurisdiction: "GOA" })}>GOA</Button>
                    <Button primary={userMeta.jurisdiction === "OCA"}
                        onClick={_ => user.setProfile({ jurisdiction: "OCA" })}>OCA</Button>
                </div>
            </div>
            <div className={Stylesheet.Field}>
                <div className={Stylesheet.Meta}>
                    <h5>languages</h5>
                    <h6>Select up to two languages that you prefer to use</h6>
                </div>
                <div className={Stylesheet.Toggles}>
                    <Button primary={userMeta.languages?.find(lang => lang === "EN") !== undefined}
                        onClick={_ => updateLangues("EN")}>english</Button>
                    <Button primary={userMeta.languages?.find(lang => lang === "GR") !== undefined}
                        onClick={_ => updateLangues("GR")}>greek</Button>
                    <Button primary={userMeta.languages?.find(lang => lang === "AR") !== undefined}
                        onClick={_ => updateLangues("AR")}>arabic</Button>
                </div>
            </div>
            {userMeta?.providerMeta
                ? null
                : <div className={Stylesheet.Field}>
                    <div className={Stylesheet.Meta}>
                        <h5>account settings</h5>
                        <h6>manage your account information such as email and password, updating these information will require authentication before allowing update to successfully complete</h6>
                        {user.isEmailVerified
                            ? null
                            : <Button onClick={resendEmailVerification}>re-send verification email</Button>}
                    </div>
                    <div className={Stylesheet.FieldSet}>
                        <div className={Stylesheet.Input}>
                            <h6>update email address</h6>
                            <Email defaultValue={userMeta.email}
                                onBlur={value => updateEmailAddress(value)} />
                        </div>
                        <div className={Stylesheet.Input}>
                            <h6>update account password</h6>
                            <Password onBlur={value => updatePassword(value)} />
                        </div>
                    </div>
                </div>}
            <div className={Stylesheet.Field}>
                <div className={Stylesheet.Meta}>
                    <h5>profile information</h5>
                    <h6>manage your profile information, these are public information so pick and choose which data you wish to be made public.</h6>
                    <Button onClick={_ => setEditProfile(current => current ? false : true)}>
                        {ico_pen_alt}&emsp;{editProfile ? "save information" : "edit information"}
                    </Button>
                </div>
                <div className={Stylesheet.FieldSet}>
                    <div className={Stylesheet.Input}>
                        <h6>update phone number</h6>
                        <Phone defaultValue={userMeta.phone || undefined} disabled={!editProfile}
                            onBlur={value => value !== "" && value !== userMeta.phone && user.setProfile({ phone: value })} />
                    </div>
                    <div className={Stylesheet.Input}>
                        <h6>update you're display name</h6>
                        <Input defaultValue={userMeta.displayName} disabled={!editProfile}
                            onBlur={value => value !== "" && value !== userMeta.displayName && user.updateDisplayName(value) as any
                                && user.setProfile({ displayName: value, firstName: value.split(' ')[0], lastName: value.split(' ')[1] })} />
                    </div>
                    <div className={Stylesheet.Input}>
                        <h6>update you're address</h6>
                        <Address defaultValue={userMeta.address} disabled={!editProfile}
                            onChange={value => user.setProfile({ address: value })} />
                    </div>
                    <div className={Stylesheet.Input}>
                        <h6>update you're birthday</h6>
                        <Datepicker defaultValue={userMeta.birthday} disabled={!editProfile}
                            onChange={value => value !== "" && value !== userMeta.birthday && user.setProfile({ birthday: value })} />
                    </div>
                </div>
            </div>
            <div className={Stylesheet.Controls}>
                <Button onClick={_ => signout()}>log out</Button>
            </div>
        </div>
    )
}

export default Settings;