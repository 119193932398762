import {show, hide, ToastState} from '../reducers/toast';
import store from '../';

const toggleToast = (action: ToastState, delay: number = 10000) => {
    store.dispatch(show(action));
    const timer = setTimeout(() => {
        store.dispatch(hide());
        clearTimeout(timer);
    }, delay);
}

export default toggleToast;