import React from 'react';
import Button from '../../../elements/Button/Button';
import DropMenu from '../../../elements/DropMenu/DropMenu';
import { ico_redo_alt } from '../../../icon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSettings } from '../../../store/reducers/editor';
import Stylesheet from './Editor.module.css';


const Editor = () => {
    const settings = useAppSelector(state => state.editor.settings);
    const setEditor = useAppDispatch();

    const restoreDefault = () => {
        setEditor(setSettings({
            realtimeUpdates: true,
            disableHTML: false,
            disableCSS: false,
            disableJS: true,
            tab: "html",
            namespace: "template"
        }))
    }

    return (
        <div className={Stylesheet.Container}>
            <h2>editor preferences</h2>
            <form>
                <div className={Stylesheet.Field}>
                    <p>realtime preview</p>
                    <h4>if enabled will automatically compile and update the previewer on every keystroke.</h4>
                    <DropMenu
                        placeholder='set realtime updates'
                        values={['enabled', 'disabled']}
                        defaultValue={settings.realtimeUpdates ? "enabled" : "disabled"}
                        onChange={value => setEditor(setSettings({ realtimeUpdates: value === "enabled" ? true : false }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <p>HTML support</p>
                    <h4>if enabled will allow embedding HTML into the template or service text.</h4>
                    <DropMenu
                        placeholder='HTML Support'
                        values={['enabled', 'disabled']}
                        defaultValue={settings.disableHTML ? "disabled" : "enabled"}
                        onChange={value => setEditor(setSettings({ disableHTML: value === "enabled" ? true : false }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <p>CSS support</p>
                    <h4>if enabled will allow embedding CSS into the template or service text.</h4>
                    <DropMenu
                        placeholder='CSS Support'
                        values={['enabled', 'disabled']}
                        defaultValue={settings.disableCSS ? "disabled" : "enabled"}
                        onChange={value => setEditor(setSettings({ disableCSS: value === "enabled" ? true : false }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <p>JavaScript support</p>
                    <h4>if enabled will allow embedding JavaScript into the template or service text.</h4>
                    <DropMenu
                        placeholder='JavsScript Support'
                        values={['enabled', 'disabled']}
                        defaultValue={settings.disableJS ? "disabled" : "enabled"}
                        onChange={value => setEditor(setSettings({ disableJS: value === "enabled" ? false : true }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <p>Active editing tab</p>
                    <h4>set which tab in the editor is active, this can also be set in the quick tools.</h4>
                    <DropMenu
                        placeholder='select active tab'
                        values={["HTML", "CSS", "JavaScript"]}
                        defaultValue={settings.tab}
                        onChange={value => setEditor(setSettings({ tab: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <p>active namespace</p>
                    <h4>set which text is currently active in the editor, either the Template text or Service Text, this can also be set in the editor controls</h4>
                    <DropMenu
                        placeholder='select active namespace'
                        values={['template', 'service-text']}
                        defaultValue={settings.namespace}
                        onChange={value => setEditor(setSettings({ namespace: value }))} />
                </div>
                <div className={Stylesheet.Controls}>
                    <Button secondary onClick={restoreDefault}>{ico_redo_alt}&emsp;restore defaults</Button>
                </div>
            </form>
        </div>
    )
}

export default Editor;