import React, { useEffect } from 'react';
import { ico_code, ico_cog } from '../../icon';
import { useAppSelector } from '../../store/hooks';
import Stylesheet from './Previewer.module.css';
import Classnames from 'classnames';

interface PreviewerProps {
    lang: "EN" | "GR" | "AR",
    frameRef: React.RefObject<HTMLIFrameElement>,
    onScroll: () => void
}

const Previewer = (props: PreviewerProps) => {
    const Editor = useAppSelector(state => state.editor);
    const ActiveServiceTexts = useAppSelector(state => state.editor.serviceTexts);
    const ActiveServiceText = Editor.serviceText?.text || ActiveServiceTexts?.find((val) => val.language === props.lang)?.text;
    const ActiveSources = useAppSelector(state => state.previewer.activeSources);
    const ActiveFontSize = useAppSelector(state => state.previewer.fontSize);
    const ActiveNamespace = useAppSelector(state => state.editor.settings.namespace);
    const ActiveTemplateText = useAppSelector(state => state.editor.template?.text);
    const EditorHide = useAppSelector(state => state.editor.settings.hideEditor);
    const EditorPreviewer = useAppSelector(state => state.editor.settings.hidePreviwer);
    const DisableEditor = useAppSelector(state => state.editor.settings.disableEditor);
    const ActiveScript = ActiveNamespace === "service-text" ? ActiveServiceText || "" : ActiveTemplateText || "";
    const containerRef: React.RefObject<HTMLDivElement> = React.createRef()

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("mouseenter", (event) => {
                if (props.frameRef.current?.contentWindow) {
                    // set active frame in session storage
                    sessionStorage.setItem("orthodoxscrollsyncframe", props.lang);
                    props.frameRef.current.contentWindow.addEventListener("scroll", () => {
                        const activeFrame = sessionStorage.getItem("orthodoxscrollsyncframe");
                        if (props.frameRef.current?.contentWindow?.scrollY && activeFrame && activeFrame === props.lang) {
                            // check if the active frame is the current frame from session storage
                            props.onScroll()
                        }
                    })
                }
            })
        }
    }, [])

    const getProcessedScript = () => {
        let script = ActiveScript;
        // process all embeded sources
        for (const source of ActiveSources || [])
            if (script.match(`<${source.shortName} />`))
                script = script.replaceAll(`<${source.shortName} />`, source.text);
        script = script.replace('<head>', `<head lang=${props.lang.toLocaleLowerCase()}>`);
        script = script.replace('<body>', `<body style="font-size: ${ActiveFontSize}pt"`);
        return script;
    }

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['HideEditor']]: EditorHide,
            [Stylesheet['HidePreviwer']]: EditorPreviewer,
            [Stylesheet['DisableEditor']]: DisableEditor
        })} ref={containerRef}>
            <div className={Stylesheet.Render}>
                {ActiveScript !== ""
                    ? <iframe
                        title='script previewer'
                        width='100%'
                        height='100%'
                        id='PreviewerWindow'
                        onScroll={event => console.log(event)}
                        className={Stylesheet.Iframe}
                        sandbox='allow-same-origin allow-scripts allow-popups'
                        ref={props.frameRef}
                        srcDoc={getProcessedScript()}
                        lang={props.lang} />
                    : (
                        <div className={Stylesheet.NoContent}>
                            <h1>{ico_code}</h1>
                            <h3>no text to show yet !</h3>
                            <h5>get started by writing out the script on the editor window on the right,<br />
                                start out by typing a simple heading tag&emsp;
                                <span className={Stylesheet.Code}>{'<h1 class = "title">Hello World !</h1>'}</span> <br />
                                for more options and configurations select the {ico_cog} icon in the toolbox.
                            </h5>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Previewer;