import Stylesheet from './Share.module.css';
import Button from '../../../elements/Button/Button';
import Classnames from 'classnames';
import { ico_comment_alt, ico_facebook, ico_twitter } from '../../../icon';
import { useAppSelector } from '../../../store/hooks';

interface ShareProps {
    show: boolean,
    toggle: () => void
}

const Share = (props: ShareProps) => {
    const editor = useAppSelector(state => state.editor);
    const serviceType = editor.settings.namespace === "service-text" ?
        editor.serviceText?.service_type : editor.template?.serviceType;
    const date = editor.settings.namespace === "service-text"
        ? new Date(`${editor.serviceText?.month}/${editor.serviceText?.date}/${editor.serviceText?.year}`).toDateString()
        : "";

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: props.show,
        [Stylesheet['hide-container']]: !props.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: props.show,
        [Stylesheet['hide-overlay']]: !props.show
    })

    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: props.show,
        [Stylesheet['hide-popup']]: !props.show
    })

    return (
        <div className={containerStyle} >
            <div className={overlayStyle}
                onClick={_ => props.toggle()}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Header}>
                    <h3>Share Service</h3>
                    <p>select which platform you would like to post the service link to.</p>
                </div>
                <div className={Stylesheet.WidgetControls}>
                    <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={`https://twitter.com/intent/tweet?hashtags=orthodoxliturgics&original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=${encodeURI(`Orthodox Liturgics  |  ${editor.serviceText?.service_type}  |  ${new Date(`${editor.serviceText?.month}/${editor.serviceText?.date}/${editor.serviceText?.year}`).toDateString()}`)}&url=${window.location.href.replaceAll('&', "%26")}`}>
                        <Button secondary className={Stylesheet.TwitterBtn}>{ico_twitter}&emsp;Twitter</Button>
                    </a>
                    <div className={Stylesheet.Line}></div>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href.replaceAll('&', "%26")}&amp;src=sdkpreparse`}>
                        <Button secondary className={Stylesheet.FacebookBtn}>{ico_facebook}&emsp;Facebook</Button>
                    </a>
                    <a
                        href={`sms://?&body=${encodeURI(`Orthodox Liturgics  |  ${serviceType}  |  ${date} ${window.location.href.replaceAll('&', "%26")}`)}`}>
                        <Button secondary className={Stylesheet.FacebookBtn}>{ico_comment_alt}&emsp;message</Button>
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Share;