import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ico_book, ico_cog, ico_user, ico_users } from '../../icon';
import { useAppSelector } from '../../store/hooks';
import Stylesheet from './Profile.module.css';
import Classnames from 'classnames';
import { Navigate } from 'react-router-dom';
import { storage, user } from '@enigma/core/services';
import toggleToast from '../../store/actions/toast.actions';
import DefaultUser from '../../assets/user-default.jpg';
import Image from '../../elements/Image/Image';
import Settings from './Settings/Settings';
import About from './About/About';
import Templates from './Templates/Templates';
import Adminstration from './Adminstration/Adminstration';

function Profile() {
    const userMeta = useAppSelector(state => state.users);
    const { tab } = useParams();

    const updateProfilePhoto = async (path: string) => {
        // check if the image file was successfully updated
        if (await storage.exists(path))
            user.setProfile({
                photoURL: path,
                photoURLSmall: path,
                photoURLLarge: path,
            }).then(_ => toggleToast({ message: "profile image updated successfully", isError: false }))
        else
            toggleToast({ message: "profile image couldnt not upadted, please try again later", isError: true })
    }

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['AboutActive']]: tab === "about",
            [Stylesheet['TemplatesActive']]: tab === "templates",
            [Stylesheet['AdminstrationActive']]: tab === "adminstration",
            [Stylesheet['SettingsActive']]: tab === "settings"
        })}>
            {userMeta.isLoggedIn ? null : <Navigate to='/' />}
            <div className={Stylesheet.Header}>
                <div className={Stylesheet.Photo}>
                    {!userMeta?.providerID
                        ? <Image
                            editable={!userMeta?.providerID && tab === "settings"}
                            disableUpdateBtn={tab !== "settings"}
                            destination={`users/${user.uid}/`}
                            accept={["image/jpeg"]}
                            src={userMeta.photoURLLarge || DefaultUser} alt='user profile'
                            onEdited={path => updateProfilePhoto(path)} />
                        : <Image src={userMeta.photoURLLarge || DefaultUser} alt='user profile' />}
                </div>
                <div className={Stylesheet.Metadata}>
                    <h3>{userMeta.displayName}</h3>
                    <h4>{userMeta.email}</h4>
                    <h4>{userMeta.role} account</h4>
                </div>
            </div>
            <div className={Stylesheet.Tabs}>
                <div className={Stylesheet.Tab}>
                    <Link to='/profile/about'>
                        <p>{ico_user}&emsp;about</p>
                    </Link>
                </div>
                {userMeta.role === "administrator" || userMeta.role === "root"
                    ? <div className={Stylesheet.Tab}>
                        <Link to='/profile/templates'>
                            <p>{ico_book}&emsp;templates</p>
                        </Link>
                    </div>
                    : null}
                {userMeta.role === "administrator" || userMeta.role === "root"
                    ? <div className={Stylesheet.Tab}>
                        <Link to='/profile/adminstration'>
                            <p>{ico_users}&emsp;adminstration</p>
                        </Link>
                    </div>
                    : null}
                <div className={Stylesheet.Tab}>
                    <Link to='/profile/settings'>
                        <p>{ico_cog}&emsp;settings</p>
                    </Link>
                </div>
            </div>
            <div className={Stylesheet.ActiveTabLine}></div>
            <div className={Stylesheet.Content}>
                {tab === "about" ? <About /> : null}
                {tab === "templates" ? <Templates /> : null}
                {tab === "adminstration" ? <Adminstration /> : null}
                {tab === "settings" ? <Settings /> : null}
            </div>
        </div>
    )
}

export default Profile;