import React, { useState } from 'react';
import Editor from './Editor/Editor';
import ServiceTexts from './ServiceTexts/ServiceTexts';
import Stylesheet from './Settings.module.css';
import Sources from './Sources/Sources';
import Template from './Template/Template';

const Settings = () => {
    type SettingTabs = "editor" | "template" | "services" | "service-text" | "sources";
    const [tab, setTab] = useState<SettingTabs>("editor")

    const getContent = () => {
        switch (tab) {
            case "editor":
                return <Editor />
            case "template":
                return <Template />
            case "service-text":
                return <ServiceTexts />
            case "sources":
                return <Sources />
            default:
                break;
        }
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Menu}>
                <p
                    onClick={_ => setTab("editor")}
                    className={tab === "editor" ? Stylesheet.Active : undefined}>
                    editor preferences</p>
                <p
                    onClick={_ => setTab("template")}
                    className={tab === "template" ? Stylesheet.Active : undefined}>
                    template</p>
                <p
                    onClick={_ => setTab("service-text")}
                    className={tab === "service-text" ? Stylesheet.Active : undefined}>
                    service text</p>
                <p
                    onClick={_ => setTab("sources")}
                    className={tab === "sources" ? Stylesheet.Active : undefined}>
                    sources</p>
            </div>
            <div className={Stylesheet.Content}>{getContent()}</div>
        </div>
    )
}

export default Settings;