import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

// Define a type for the slice state
interface AppState {
    theme: "dark" | "light",
    toggleMenu: boolean,
    scrollPosition?: number
}
// Define the initial state using that type
const initialState: AppState = {
    theme: "light",
    toggleMenu: false
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleTheme: (state, action: PayloadAction<"dark" | "light">) => {
            return { ...state, theme: action.payload };
        },
        setScrollPosition: (state, action: PayloadAction<number | undefined>) => {
            return { ...state, scrollPosition: action.payload };
        },
        toggleMenu: (state, action: PayloadAction<boolean | undefined>) => {
            return { ...state, toggleMenu: action.payload !== undefined ? action.payload : !state.toggleMenu };
        }
    }
})

export const { toggleTheme, toggleMenu, setScrollPosition } = appSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.app;

export default appSlice.reducer;