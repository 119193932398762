import React, { useEffect, useState } from 'react';
import Stylesheet from './Address.module.css';
import useDebounce from '../../hooks/debounce';
import axios from 'axios';
import Button from '../Button/Button';
import { ico_map_marker_alt } from '../../icon';
import Classnames from 'classnames';

export interface GeocodingAPIInterface {
    address_components: Array<{
        long_name: string,
        short_name: string,
        types: Array<string>
    }>,
    formatted_address: string,
    geometry: {
        bounds?: {
            northwest: { lat: number, lng: number },
            southwest: { lat: number, lng: number }
        },
        location: { lat: number, lng: number },
        location_type: string,
        viewport: {
            northwest: { lat: number, lng: number },
            southwest: { lat: number, lng: number }
        }
    },
    place_id: string,
    types: Array<string>
}

interface AddressProps {
    icon?: JSX.Element,
    type?: "text",
    defaultValue?: GeocodingAPIInterface,
    className?: string,
    onChange?: (address: GeocodingAPIInterface) => void,
    placeholder?: string,
    disabled?: boolean,
    required?: boolean
}

export const formatAddress = (address: GeocodingAPIInterface) => {
    let finalAddress = "";
    for (const segment of address.address_components) {
        if (segment.types.includes("country"))
            finalAddress += segment.short_name + " ";
        else finalAddress += segment.long_name + ", ";
    }
    return finalAddress;
}

const Address = (props: AddressProps) => {
    const [selectedAddress, setSelectedAddress] = useState<GeocodingAPIInterface>()
    const [search, setSearch] = useState("");
    const [result, setResult] = useState<Array<GeocodingAPIInterface>>([]);
    const [loading, setLoading] = useState(false);
    const debouceSearchTerm = useDebounce(search, 300);

    useEffect(() => {
        if (debouceSearchTerm) {
            if (props.defaultValue && search === formatAddress(props.defaultValue)) return;
            setLoading(true);
            console.log(search.split(' ').join('+'))
            // lookup address
            axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${search.split(' ').join('+')}&key=AIzaSyAEexegzc9AbBhYhXgtARY9e5TChVfOVRY`)
                .then(result => {
                    setResult(result.data.results);
                    setLoading(false);
                });
        } else {
            setResult([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouceSearchTerm]);

    useEffect(() => {
        if (props.defaultValue && selectedAddress?.place_id !== props.defaultValue.place_id) {
            setSelectedAddress(props.defaultValue || "");
            setSearch(formatAddress(props.defaultValue));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue]);

    useEffect(() => {
        if (selectedAddress) setSearch(formatAddress(selectedAddress))
        if (props.onChange && selectedAddress 
                && selectedAddress?.place_id !== props.defaultValue?.place_id) {
                    props.onChange(selectedAddress);
                    console.log("address changed")
                }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    return (
        <div className={Stylesheet.Container}>
            {props?.icon ? <h5 className={Stylesheet.Icon}>{props.icon}</h5> : null}
            <input type={props?.type || "text"}
                value={search}
                className={props?.className}
                onChange={event => setSearch(event.target.value)}
                placeholder={props?.placeholder || "enter value"}
                disabled={props?.disabled || false}
                required={props?.required || false} />
            <Button disabled={props.disabled} icon minimal loading={loading}>{ico_map_marker_alt}</Button>
            <div className={Classnames(Stylesheet.AddressList, {
                [Stylesheet.ShowAddressList]: result.length !== 0,
                [Stylesheet.HideAddressList]: result.length === 0 
                    || props.disabled
                    || (props.defaultValue && search === formatAddress(props.defaultValue))
            })}>
                {result.map((value, index) => (
                    <p key={index + Math.random()}
                        onClick={_ => setSelectedAddress(value)}>
                            {formatAddress(value)}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default Address;