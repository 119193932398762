import React from 'react';
import { firestore } from '@enigma/core/services';
import util from '@enigma/core/utilities';
import Button from '../../../elements/Button/Button';
import DropMenu from '../../../elements/DropMenu/DropMenu';
import Input from '../../../elements/Input/Input';
import Number from '../../../elements/Number/Number';
import { ico_save } from '../../../icon';
import toggleToast from '../../../store/actions/toast.actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setServiceText } from '../../../store/reducers/editor';
import Stylesheet from './ServiceTexts.module.css';

const ServiceTexts = () => {
    const serviceText = useAppSelector(state => state.editor.serviceText);
    const setEditor = useAppDispatch();

    const saveServiceText = () => {
        if (!serviceText) return;
        if (serviceText && serviceText.id)
            firestore.collection('service-texts')
                .doc(serviceText.id)
                .set(serviceText)
                .then(_ => toggleToast({ message: `service text successfully updated`, isError: false }))
        else
            firestore.collection('service-text')
                .add(serviceText)
                .then(_ => toggleToast({ message: `service text successfully created`, isError: false }))
    }

    if (!serviceText) {
        return (
            <div className={Stylesheet.Container}>
                <h2>service-text</h2>
                <div className={Stylesheet.NoActiveServiceText}>
                    <h3>no active service text</h3>
                    <p>there is currently no active service-text selected, please select a service-text under <i>services</i> and try again</p>
                </div>
            </div>
        )
    }

    return (
        <div className={Stylesheet.Container}>
            <h2>service-text</h2>
            <form onSubmit={event => { event.preventDefault(); saveServiceText(); }}>
                <div className={Stylesheet.Field}>
                    <h5>select feast day</h5>
                    <DropMenu
                        placeholder='template feast'
                        values={util.date.getCalendarDays()}
                        defaultValue={serviceText?.feast}
                        onChange={value => setEditor(setServiceText({ feast: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>enter month</h5>
                    <Number
                        placeholder='service text month'
                        min={1}
                        max={12}
                        defaultValue={serviceText?.month}
                        onChange={value => setEditor(setServiceText({ month: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>enter date</h5>
                    <Number
                        placeholder='service text month'
                        min={1}
                        max={31}
                        defaultValue={serviceText?.date}
                        onChange={value => setEditor(setServiceText({ date: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>enter year</h5>
                    <Number
                        placeholder='service text month'
                        min={new Date().getUTCFullYear()}
                        defaultValue={serviceText?.year}
                        onChange={value => setEditor(setServiceText({ year: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>enter supported language for service text, i.e En,Fr</h5>
                    <Input
                        placeholder='service-text languages'
                        defaultValue={typeof serviceText?.language === "string" ? serviceText.language : serviceText?.language}
                        onBlur={value => setEditor(setServiceText({ language: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select template jurisdiction</h5>
                    <DropMenu
                        placeholder='template jurisdiction'
                        values={["antiochian", "greek", "OCA"]}
                        defaultValue={serviceText?.jurisdiction}
                        onChange={value => setEditor(setServiceText({ jurisdiction: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>enter service-text type</h5>
                    <Input
                        placeholder='service-text type'
                        defaultValue={serviceText?.service_type}
                        onBlur={value => setEditor(setServiceText({ service_type: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <Button primary type="submit">{ico_save}&emsp;save changes</Button>
                </div>
            </form>
        </div>
    )
}

export default ServiceTexts;