import React from 'react';
import Stylesheet from './toast.module.css';
import Classnames from 'classnames';
import { useAppSelector } from '../../store/hooks';


function Toast() {
    const toast = useAppSelector(state => state.toast);

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['show']]: toast.show,
            [Stylesheet['hide']]: !toast.show,
            [Stylesheet['error']]: toast.isError,
            [Stylesheet['message']]: !toast.isError
        })}>
            <h5>{toast.message}</h5>
        </div>
    )
}

export default Toast;