import { firestore } from '@enigma/core/services';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../elements/Button/Button';
import RadioButton from '../../elements/RadioButton/RadioButton';
import { ico_check, ico_eye_slash, ico_link } from '../../icon';
import toggleToast from '../../store/actions/toast.actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setTemplate, TemplateInterface } from '../../store/reducers/editor';
import Stylesheet from './Template.module.css';

const Template = (props: TemplateInterface) => {
    const userState = useAppSelector(state => state.users);
    const setEditor = useAppDispatch();

    const approveChange = async () => {
        // validate privilages
        if (userState.role === "root" || (userState.role === "administrator" && props.commitRole !== userState.role)) {
            const changes = { ...props };
            delete changes.remark;
            delete changes.commitBy;
            delete changes.commitRole;
            // commit changes
            firestore.collection("templates")
                .doc(props.id).update({ ...changes, version: (props.version || 0) + .1 })
                .then(_ => {
                    // remove staged commit
                    firestore.collection("unverifiedTemplates").doc(props.id).delete()
                        .then(_ => toggleToast({
                            message: "template changes approved",
                            isError: false
                        }))
                })
                .catch(_ => toggleToast({
                    message: "template could not be updated.",
                    isError: true
                }))
        } else {
            toggleToast({
                message: "unauthorized request, elevated privileges required",
                isError: true
            })
        }
    }

    const toggleVerification = (state: boolean) => {
        firestore.collection(props.collection || "templates")
            .doc(props.id).update({ verified: state })
            .then(_ => toggleToast({
                message: "template successfully updated",
                isError: false
            }))
            .catch(_ => toggleToast({
                message: "template could not be updated.",
                isError: true
            }))
    }

    const getLanguages = () => {
        if (!props.language) return null;
        switch (props.language) {
            case "GR":
                return "greek";
            case "AR":
                return "arabic";
            default:
                return "english";
        }
    }

    return (
        <div className={Stylesheet.Container}>
            {props?.remark ? <h6>{ico_eye_slash}&emsp;update request</h6> : null}
            <div className={Stylesheet.Header}>
                <Link to={`/view/${props.id}`} onClick={_ => setEditor(setTemplate(props))}>
                    {<h4 className={props.name ? undefined : Stylesheet.NoType}>
                        {ico_link}&emsp;{props.name || props.serviceType || "no title provided"}</h4>}
                </Link>
                <RadioButton defaultValue={props.verified} onChange={state => toggleVerification(state)} />
            </div>
            <div className={Stylesheet.Meta}>
                <h5>{props.timestamp || new Date().toDateString()}&emsp;&middot;&emsp;{props.jurisdiction} - {getLanguages()}</h5>
            </div>
            {props.remark
                ? <div className={Stylesheet.Commit}>
                    <p><i>"{props.remark}"&nbsp;~&nbsp;{props.commitBy}</i></p>
                    <Button secondary onClick={approveChange}>{ico_check}&emsp;approve</Button>
                </div>
                : null}
        </div>
    )
}

export default Template;