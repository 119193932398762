import React, { useEffect, useState } from 'react';
import Stylesheet from './Number.module.css';

interface NumberProps {
    icon?: JSX.Element,
    defaultValue?: number,
    min?: number,
    max?: number,
    placeholder?: string,
    onChange?: (value: number) => void,
    onBlur?: (value: number) => void,
    disabled?: boolean,
    required?: boolean
}

const Number = (props: NumberProps) => {
    const [value, setValue] = useState<number>(props?.defaultValue || props?.min || 0);
    useEffect(() => {
        if (props?.defaultValue && value !== props.defaultValue)
            setValue(props.defaultValue || props?.min || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    const onChange = (value: string) => {
        setValue(parseFloat(value));
        if (props.onChange) props.onChange(parseFloat(value));
    }
    return (
        <div className={Stylesheet.Container}>
            {props?.icon
                ? <h5 className={Stylesheet.Icon}>{props.icon}</h5>
                : null}
            <input type="number"
                onChange={event => onChange(event.target.value)}
                onBlur={_ => props?.onBlur && props?.onBlur(value)}
                placeholder={props?.placeholder || "enter value"}
                disabled={props?.disabled || false}
                required={props?.required || false}
                value={value}
                min={props?.min}
                max={props?.max} />
        </div>
    )
}

export default Number;