import React, { useEffect, useState } from 'react';
import Stylesheet from './RadioButton.module.css';
import Classnames from 'classnames';

interface RadioButtonProps {
    defaultValue?: boolean,
    onChange?: (value: boolean) => void,
    children?: JSX.Element
}

const RadioButton = (props: RadioButtonProps) => {
    const [selected, setSelected] = useState(props.defaultValue || false);
    useEffect(() => {
        if (props.defaultValue && props.defaultValue !== selected)
            setSelected(props.defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue])

    const onChange = () => {
        setSelected(selected ? false : true);
        if (props.onChange) props.onChange(selected ? false : true);
    }

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['Selected']]: selected
        })} onClick={onChange}>
            <div className={Stylesheet.Radio}></div>
            {props?.children
            ? <h5>{props.children}</h5>
            : null}
        </div>
    )
}

export default RadioButton;