import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GeocodingAPIInterface } from '../../elements/Address/Address';
import type { RootState } from '../index';
import { TemplateJurisdiction, TemplateLanguage } from './editor';

// Define a type for the slice state
export interface UserState {
    id?: string,
    first_name?: string,
    last_name?: string,
    displayName?: string,
    dob?: string,
    address?: GeocodingAPIInterface,
    photoURL?: string,
    photoURLSmall?: string,
    photoURLLarge?: string,
    email?: string,
    role?: "administrator" | "user" | "reviewer" | "root",
    image?: string,
    phone?: string | null,
    birthday?: string,
    gender?: string,
    parish?: string,
    providerMeta?: any,
    providerID?: string,
    jurisdiction?: TemplateJurisdiction,
    languages?: Array<TemplateLanguage>,
    isLoggedIn?: boolean,
    disabled?: boolean
}
// Define the initial state using that type
const initialState: UserState = {
    isLoggedIn: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<UserState>) => {
            return action.payload;
        },
        logout: state => {
            return { isLoggedIn: false }
        }
    }
})

export const { login, logout } = userSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.users;

export default userSlice.reducer