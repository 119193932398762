import util from '@enigma/core/utilities';
import React, { useEffect, useState } from 'react';
import { ico_calendar_alt } from '../../icon';
import Stylesheet from './Datepicker.module.css';

interface DatepickerProps {
    defaultValue?: string,
    min?: string | number | Date,
    max?: string | number | Date,
    icon?: boolean,
    required?: boolean,
    disabled?: boolean,
    onChange?: (value: string) => void
}

const Datepicker = (props: DatepickerProps) => {
    const [value, setValue] = useState(props?.defaultValue ||  null)
    useEffect(() => {
        setValue(props?.defaultValue || null);
    }, [props])
    const minDate = () => {
        if (!props?.min) return undefined;
        const date = new util.cdate(props.min);
        return date.format("yyyy-mm-dd");
    }
    const maxDate = () =>  {
        if (!props?.max) return undefined;
        const date = new util.cdate(props.max);
        return date.format("yyyy-mm-dd");
    }
    const defaultValue = () => {
        if (!value) return "";
        const date = new util.cdate(value);
        return date.format("yyyy-mm-dd");
    }

    return (
        <div className={Stylesheet.Container}>
            {props?.icon
            ? <h5 className={Stylesheet.Icon}>{ico_calendar_alt}</h5>
            : null}
            <input 
            type="date"
            min={minDate()} 
            max={maxDate()}
            value={defaultValue()}
            required={props?.required || false}
            disabled={props?.disabled || false}
            onChange={event => props?.onChange && props.onChange(event.target.value)}/>
        </div>
    )
}

export default Datepicker;