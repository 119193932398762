import React, { useState } from 'react';
import Stylesheet from './Settings.module.css';
import Button from '../../../elements/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Classnames from 'classnames';
import { setSettings } from '../../../store/reducers/previewer';
import toggleToast from '../../../store/actions/toast.actions';
import { setServiceText, toggleEditor } from '../../../store/reducers/editor';
import { Navigate, useNavigate } from 'react-router-dom';

interface SettingsProps {
    show: boolean,
    toggle: () => void
}

const Settings = (props: SettingsProps) => {
    const previewer = useAppSelector(state => state.previewer);
    const editor = useAppSelector(state => state.editor);
    const setPreviewer = useAppDispatch();
    const setEditor = useAppDispatch();
    const navigate = useNavigate();

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: props.show,
        [Stylesheet['hide-container']]: !props.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: props.show,
        [Stylesheet['hide-overlay']]: !props.show
    })

    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: props.show,
        [Stylesheet['hide-popup']]: !props.show
    })

    const updateLanguage = (lang: "EN" | "AR" | "GR") => {
        const foundIndex = previewer.settings.language.findIndex((value) => value === lang);
        if (previewer.settings.language.length === 2 && foundIndex === -1) {
            toggleToast({ isError: true, message: "only 2 languages are allowed at a time" });
            return;
        }
        if (previewer.settings.language.length === 1 && foundIndex !== -1) {
            // there must be at most one language selected
            toggleToast({ isError: true, message: "there must be at least 1 language selected" })
            return;
        }
        const updatedLanguages = foundIndex === -1 ? [...previewer.settings.language, lang] : previewer.settings.language?.filter((value) => value !== lang) || [lang];
        // add to list of languages
        setPreviewer(setSettings({ ...previewer.settings, language: updatedLanguages }))
        // check if language is selected or deselected
        if (updatedLanguages.length === 2) {
            if (!editor.settings.hideEditor) setEditor(toggleEditor());
            setEditor(setServiceText(null));
        } else {
            // update active service text
            // get requested language (This will always yealed true)
            const service = editor.serviceTexts?.find((val) => val.language === updatedLanguages[0]);
            if (service) setEditor(setServiceText(service));
        }
        // service text set, direct link asumed
        const meta = window.location.search.replace('?', "")
        .replace("month=", "").replace("date=", "")
        .replace("year=", "").replace("type=", "")
        .replaceAll("lang=", "").replaceAll("jurisdiction=", "")
        .replaceAll("%26", "&").split("&");
        navigate(window.location.pathname + window.location.search.replace(`lang=${meta[4]}`, `lang=${updatedLanguages.join(',')}`))
    }

    const updateJurisdiction = (jurisdiction: "AANA" | "GOA" | "OCA") => {
        if (previewer.settings.jurisdiction === jurisdiction) return;
        setPreviewer(setSettings({ ...previewer.settings, jurisdiction: jurisdiction }))
        // update active service text
        // get requested language (This will always yealed true)
        const service = editor.serviceTexts?.find((val) => val.jurisdiction === jurisdiction);
        if (service) setEditor(setServiceText(service));

        navigate(window.location.pathname + window.location.search.replace(`jurisdiction=${previewer.settings.jurisdiction}`, `jurisdiction=${jurisdiction}`))
    }

    return (
        <div className={containerStyle}>
            <div className={overlayStyle}
                onClick={_ => props.toggle()}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Header}>
                    <h3>Select language(s)</h3>
                </div>
                <div className={Stylesheet.WidgetControls}>
                    <Button
                        primary={previewer.settings.language.includes("EN")}
                        secondary={!previewer.settings.language.includes("EN")}
                        disabled={editor.serviceTexts?.find((val) => val.language === "EN") ? false : true}
                        onClick={_ => updateLanguage("EN")}>
                        English</Button>
                    <Button
                        primary={previewer.settings.language.includes("GR")}
                        secondary={!previewer.settings.language.includes("GR")}
                        disabled={editor.serviceTexts?.find((val) => val.language === "GR") ? false : true}
                        onClick={_ => updateLanguage("GR")}>
                        Greek</Button>
                    <Button
                        primary={previewer.settings.language.includes("AR")}
                        secondary={!previewer.settings.language.includes("AR")}
                        disabled={editor.serviceTexts?.find((val) => val.language === "AR") ? false : true}
                        onClick={_ => updateLanguage("AR")}>
                        Arabic</Button>
                </div>
                <div className={Stylesheet.Header}>
                    <h3>Select jurisdiction</h3>
                </div>
                <div className={Stylesheet.WidgetControls}>
                    <Button
                        primary={previewer.settings.jurisdiction === "AANA"}
                        secondary={previewer.settings.jurisdiction !== "AANA"}
                        disabled={editor.serviceTexts?.find((val) => val.jurisdiction === "AANA") ? false : true}
                        onClick={_ => updateJurisdiction("AANA")}>
                        Antiochian</Button>
                    <Button
                        primary={previewer.settings.jurisdiction === "GOA"}
                        secondary={previewer.settings.jurisdiction !== "GOA"}
                        disabled={editor.serviceTexts?.find((val) => val.jurisdiction === "GOA") ? false : true}
                        onClick={_ => updateJurisdiction("GOA")}>
                        GOA</Button>
                    <Button
                        primary={previewer.settings.jurisdiction === "OCA"}
                        secondary={previewer.settings.jurisdiction !== "OCA"}
                        disabled={editor.serviceTexts?.find((val) => val.jurisdiction === "OCA") ? false : true}
                        onClick={_ => updateJurisdiction("OCA")}>
                        OCA</Button>
                </div>
            </div>
        </div >
    )
}

export default Settings;