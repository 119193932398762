import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

export enum MessageBoxResponses {
    authenticated = "authenticated",
    cancled = "cancled",
    yes = "yes",
    no = "no",
    ok = "ok",
    cancle = "cancle",
    retry = "retry",
    approve = "approve",
    deny = "deny",
    confirm = "confirm"
}

export enum MessageBoxTypes {
    authenticate = "authenticate",
    yes_no = "yes_no",
    ok_cancle = "ok_cancle",
    retry = "retry",
    approve_deny = "approve_deny",
    prompt = "prompt"
}
// Define a type for the slice state
export interface MessageBoxState {
    title?: string,
    prompt?: string,
    show?: boolean,
    type?: MessageBoxTypes,
    response?: MessageBoxResponses | string
}
// Define the initial state using that type
const initialState: MessageBoxState = {
    title: "",
    type: MessageBoxTypes.yes_no,
    show: false
}

export const messageBoxSlice = createSlice({
    name: 'messageBox',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<MessageBoxState>) => {
            return { ...action.payload, response: undefined, show: true };
        },
        close: (state, action: PayloadAction<MessageBoxState>) => {
            return { ...state, ...action.payload, show: false };
        }
    }
})

export const { show, close } = messageBoxSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectMessageBox = (state: RootState) => state.messageBox;

export default messageBoxSlice.reducer;