import React, { useState } from 'react';
import { ico_phone } from '../../icon';
import Stylesheet from './Phone.module.css';

interface PhoneProps {
    icon?: boolean,
    placeholder?: string,
    defaultValue?: string,
    required?: boolean,
    onChange?: (value: string) => void,
    onBlur?: (value: string) => void,
    disabled?: boolean
}

function Phone(props: PhoneProps) {
    const [value, setValue] = useState(props.defaultValue || "");
    const [key, setKey] = useState("");
    const [separatorRemoved, setSeparatorRemoved] = useState(false);
    const onChange = (newValue: string) => {
        // format number with pattern
        if (newValue.length === 3 || newValue.length === 7) {
            if (key === "Backspace" && separatorRemoved) {
                newValue = newValue.substring(0, newValue.length-1)
            } else newValue += "-";
        }
        setValue(newValue);
        setSeparatorRemoved(newValue[newValue.length-1] === "-");
        if (props.onChange) props.onChange(newValue);
    }
    const onBlur = () => {
        console.log(value);
        if (value.length >= 12 && props.onBlur) props.onBlur(value);
    }
    return (
        <div className={Stylesheet.Container}>
            {props?.icon ? <h5 className={Stylesheet.Icon}>{ico_phone}</h5> : null}
            <input
                onBlur={_ => onBlur()}
                onChange={event => onChange(event.target.value)}
                placeholder={props?.placeholder || '123-456-7891'}
                onKeyDown={event => setKey(event.key)}
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={value}
                required={props?.required || false} 
                disabled={props.disabled || false}/>
        </div>
    )
}

export default Phone;