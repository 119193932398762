import { firestore, user } from '@enigma/core/services';
import util from '@enigma/core/utilities';
import { useEffect } from 'react';
import Button from '../../../elements/Button/Button';
import Checkbox from '../../../elements/Checkbox/Checkbox';
import DropMenu from '../../../elements/DropMenu/DropMenu';
import Input from '../../../elements/Input/Input';
import Number from '../../../elements/Number/Number';
import { ico_save } from '../../../icon';
import { toggleMessageBox } from '../../../store/actions/messageBox.actions';
import toggleToast from '../../../store/actions/toast.actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ServiceType, setTemplate, TemplateJurisdiction, TemplateLanguage } from '../../../store/reducers/editor';
import { MessageBoxResponses, MessageBoxTypes } from '../../../store/reducers/messageBox';
import Stylesheet from './Template.module.css';


const Template = () => {
    const userState = useAppSelector(state => state.users);
    const template = useAppSelector(state => state.editor.template);
    const setEditor = useAppDispatch();

    useEffect(() => {
        if (template?.fixedDate)
            setEditor(setTemplate({
                daysFromPascha: "",
                endDaysFromPascha: ""
            }))
        else
            setEditor(setTemplate({
                startDate: 0,
                endDate: 0,
                startMonth: 0,
                endMonth: 0
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template?.fixedDate]);

    const saveTemplate = async () => {
        if (template) {
            if (userState.role !== "root") {
                const response = await toggleMessageBox({ title: "enter remark", prompt: "please enter a short remark that identifies what was changed and why.", type: MessageBoxTypes.prompt });
                if (response === "") {
                    toggleToast({ message: "remark can not be empty", isError: true })
                    return;
                }
                if (response === MessageBoxResponses.cancle) return;
                firestore.collection("unverifiedTemplates").doc(template?.id)
                    .set({ ...template, remark: response, commitBy: user.email, commitRole: userState.role }).then(_ => {
                        toggleToast({
                            message: `template successfully updated, please wait until the changes are confirmed by an adminstrator."`,
                            isError: false
                        })
                    })
            } else {
                firestore.collection("templates").doc(template?.id)
                    .set({ ...template, version: (template.version || 0) + .1 }).then(_ => {
                        toggleToast({
                            message: `template successfully updated.`,
                            isError: false
                        })
                    })
            }
        }
    }


    return (
        <div className={Stylesheet.Container}>
            <h2>template</h2>
            <form onSubmit={event => { event.preventDefault(); saveTemplate(); }}>
                <div className={Stylesheet.Field}>
                    <h5>template name</h5>
                    <Input
                        placeholder='template name'
                        defaultValue={template?.name}
                        onBlur={value => setEditor(setTemplate({ name: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>rank</h5>
                    <Number
                        placeholder='template name'
                        min={1}
                        max={5}
                        defaultValue={template?.rank}
                        onBlur={value => setEditor(setTemplate({ rank: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select service type</h5>
                    <DropMenu
                        placeholder='select a service type'
                        values={["Vespers", "Orthros", "Divine Liturgy", "Vesperal Liturgy", "Paraklesis", "Holy Week", "Pascha", "Other"]}
                        defaultValue={template?.serviceType}
                        onChange={(value: ServiceType) => setEditor(setTemplate({ serviceType: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select language</h5>
                    <DropMenu
                        placeholder='template languages'
                        values={["EN", "GR", "AR"]}
                        defaultValue={template?.language}
                        onChange={(value: TemplateLanguage) => setEditor(setTemplate({ language: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select jurisdiction</h5>
                    <DropMenu
                        placeholder='select jurisdiction'
                        values={["AANA", "GOA", "OCA"]}
                        defaultValue={template?.jurisdiction}
                        onChange={(value: TemplateJurisdiction) => setEditor(setTemplate({ jurisdiction: value }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select date setting</h5>
                    <div className={Stylesheet.Fieldset}>
                        <Checkbox text='fixed' value={!!template?.fixedDate} onChange={value => setEditor(setTemplate({ fixedDate: value }))} />
                        <Checkbox text='relative to pascha' value={!template?.fixedDate} onChange={value => setEditor(setTemplate({ fixedDate: !value }))} />
                    </div>
                </div>
                {template?.fixedDate
                    ? <div className={Stylesheet.Field}>
                        <h5>set fixed date</h5>
                        <div className={Stylesheet.Fieldset}>
                            <div className={Stylesheet.Field}>
                                <h5>start date</h5>
                                <div className={Stylesheet.Fieldset}>
                                    <DropMenu
                                        placeholder='select month'
                                        values={util.date.getCalendarMonths(true)}
                                        defaultValue={util.date.getCalendarMonths(true)[template?.startMonth ? template?.startMonth - 1 : 0]}
                                        onChange={(value: string) => setEditor(setTemplate({ startMonth: util.date.getCalendarMonths(true).findIndex((month) => month === value) + 1 }))} />
                                    <Number
                                        min={1}
                                        placeholder="set date"
                                        defaultValue={parseInt(`${template?.startDate || 1}`)}
                                        onChange={value => setEditor(setTemplate({ startDate: value }))} />
                                </div>
                            </div>
                            <div className={Stylesheet.Field}>
                                <h5>end date</h5>
                                <div className={Stylesheet.Fieldset}>
                                    <DropMenu
                                        placeholder='select month'
                                        values={util.date.getCalendarMonths(true)}
                                        defaultValue={util.date.getCalendarMonths(true)[template?.endMonth ? template?.endMonth - 1 : 0]}
                                        onChange={(value: string) => setEditor(setTemplate({ endMonth: util.date.getCalendarMonths(true).findIndex((month) => month === value) + 1 }))} />
                                    <Number
                                        min={1}
                                        placeholder="set date"
                                        defaultValue={parseInt(`${template?.endDate || 1}`)}
                                        onChange={value => setEditor(setTemplate({ endDate: value }))} />
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className={Stylesheet.Field}>
                        <h5>set date relative to pascha</h5>
                        <div className={Stylesheet.Fieldset}>
                            <div className={Stylesheet.Field}>
                                <h5>start days</h5>
                                <Number
                                    placeholder="start date"
                                    defaultValue={parseInt(`${template?.daysFromPascha || 0}`)}
                                    onChange={value => setEditor(setTemplate({ daysFromPascha: value }))} />
                            </div>
                            <p>to</p>
                            <div className={Stylesheet.Field}>
                                <h5>end days</h5>
                                <Number
                                    placeholder="start date"
                                    defaultValue={parseInt(`${template?.endDaysFromPascha || 0}`)}
                                    onChange={value => setEditor(setTemplate({ endDaysFromPascha: value }))} />
                            </div>
                        </div>
                    </div>}

                <div className={Stylesheet.Field}>
                    <h5>update the day of the week for template</h5>
                    {util.date.getCalendarDays(true).map((day, index) => (
                        <Checkbox
                            text={day}
                            value={template?.dayOfWeek === day}
                            onChange={(value: boolean) => setEditor(setTemplate({ dayOfWeek: value ? day : undefined }))}
                            key={index + Math.random()} />
                    ))}
                </div>
                <div className={Stylesheet.Field}>
                    <h5>select if the template has been verified (Note: Only users with elivated privileges can update this)</h5>
                    <DropMenu
                        disabled={userState.role === "reviewer"}
                        placeholder='verified'
                        values={["true", "false"]}
                        defaultValue={template?.verified ? "true" : "false"}
                        onChange={value => setEditor(setTemplate({ verified: value === "true" ? true : false }))} />
                </div>
                <div className={Stylesheet.Field}>
                    <Button primary type="submit">{ico_save}&emsp;save template changes</Button>
                </div>
            </form>
        </div>
    )
}

export default Template;