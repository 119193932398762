import { show, hide } from '../reducers/modal';
import store from '../';

export const showModal = (type: "settings") => {
    store.dispatch(show({ type: "settings" }));
}

export const closeModal = () => {
    store.dispatch(hide());
}
