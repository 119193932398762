import util from '@enigma/core/utilities';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ServiceTextInterface, setServiceText } from '../../store/reducers/editor';
import Stylesheet from './Service.module.css';
import Classnames from 'classnames';

interface ServiceProps {
    list?: boolean,
    active?: boolean,
    data: ServiceTextInterface,
    onClick: () => void
}

const Service = (props: ServiceProps) => {
    const setEditor = useAppDispatch();
    const userLangs = useAppSelector(state => state.users.languages);
    const userJuris = useAppSelector(state => state.users.jurisdiction);

    if (props.list) {
        // render service as a list item
        return (
            <div className={Classnames(Stylesheet.Container, Stylesheet.Active)}>
                <p>{props.data.service_type}</p>
                <p>
                    {new util.cdate(`${props.data.month}/${props.data.date}/${props.data.year}`).getCalendarMonth(false)}&nbsp;
                    {props.data.date},&nbsp;{props.data.year}
                </p>
            </div>
        )
    }

    return (
        <div className={Stylesheet.Container}>
            <Link to={`/view/${props.data.template}?month=${props.data.month}&date=${props.data.date}&year=${props.data.year}&type=${props.data.service_type}&lang=${userLangs?.join(',').toUpperCase() || "EN"}&jurisdiction=${userJuris || "AANA"}`}
                onClick={_ => {
                    sessionStorage.setItem("orthodoxliturgics", JSON.stringify(props));
                    setEditor(setServiceText(props.data));
                    props.onClick();
                }}>
                <p>{props.data.service_type}</p>
                <p>
                    {new util.cdate(`${props.data.month}/${props.data.date}/${props.data.year}`).getCalendarMonth(false)}&nbsp;
                    {props.data.date},&nbsp;{props.data.year}
                </p>
            </Link>
        </div>
    )
}

export default Service;