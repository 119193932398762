import React, { useEffect, useState } from 'react';
import { ico_check } from '../../icon';
import Stylesheet from './Checkbox.module.css';
import Classnames from 'classnames';

interface CheckboxInterface {
    value: boolean,
    text: string,
    onChange?: (value: boolean) => void
}

const Checkbox = (props: CheckboxInterface) => {
    const [checked, setChecked] = useState(props?.value);
    useEffect(() => {
        setChecked(props.value)
    }, [props?.value])
    const onSelect = () => {
        const value = checked ? false : true;
        if (props.onChange) props.onChange(value);
        setChecked(value)
    }
    return (
        <div className={Classnames(Stylesheet.Container,
            { [Stylesheet['Checked']]: checked })}
            onClick={onSelect}>
            <h5>{ico_check}</h5>
            <p>{props.text}</p>
        </div>
    )
}

export default Checkbox;