import util from '@enigma/core/utilities';
import axios from 'axios';
import React, { useState } from 'react';
import Service from '../../components/Service/Service';
import Calendar from '../../elements/Calendar/Calendar';
import { ico_book } from '../../icon';
import toggleToast from '../../store/actions/toast.actions';
import { ServiceTextInterface, setServiceTexts } from '../../store/reducers/editor';
import Stylesheet from './Home.module.css';
import Logo from '../../assets/logo.png';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function Home() {
    const [collection, setCollection] = useState<Array<ServiceTextInterface>>([]);
    const [services, setServices] = useState<Array<ServiceTextInterface>>([]);
    const [loading, setLoading] = useState(false);
    const userState = useAppSelector(state => state.users);
    const dispatch = useAppDispatch();
    const sessionDate = parseInt(sessionStorage.getItem('orthodoxcalendar') || "-1");

    const setActiveCollection = (service: ServiceTextInterface) => {
        const results = collection.filter((val) => val.service_type === service.service_type);
        dispatch(setServiceTexts(results));
    }

    const getServices = () => {
        if (loading) {
            return (
                <div className={Stylesheet.Loading}>
                    <div className={Stylesheet.Loading}>
                        <img src={Logo} alt="loading" />
                    </div>
                </div>
            )
        }
        if (services.length === 0 || (services.length === 1 && !services[0]?.service_type))
            return (
                <div className={Stylesheet.NoServices}>
                    <h1>{ico_book}</h1>
                    <p>no services found for selected date, please select another date.</p>
                </div>
            )
        else {
            return (
                services.map((value, index) => (
                    <React.Fragment key={index + Math.random()}>
                        <Service onClick={() => setActiveCollection(value)} data={value} />
                    </React.Fragment>
                ))
            )
        }
    }

    const lookupService = (timestamp: number) => {
        setLoading(true);
        const date = new util.cdate(timestamp);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getYear();
        sessionStorage.setItem("orthodoxcalendar", timestamp.toString());
        axios.get(`https://us-central1-typikon-1.cloudfunctions.net/parser?day=${day}&month=${month}&year=${year}`)
            .then(res => {
                // sort services into collections
                let collection: Array<ServiceTextInterface> = res.data;
                let result: Array<ServiceTextInterface> = [];
                for (const serviceText of collection) {
                    // identify default language and juisdiction settings
                    const defaultLang = userState.languages || ["EN"];
                    const defaultJurisdiction = userState.jurisdiction || "AANA";
                    const service = collection.find(
                        (val) => val.service_type === serviceText.service_type
                            && defaultLang.find((lang) => lang === val.language)
                            && val.jurisdiction === defaultJurisdiction
                    );
                    if (result.find((val) => val.service_type === serviceText.service_type)) continue;
                    if (service) result.push(service)
                }
                setServices(result);
                setCollection(collection);
                setLoading(false);
            })
            .catch(err => toggleToast({ isError: true, message: "unable to look for services, please try again later." }))
    }

    return (
        <div className={Stylesheet.Container}>
            <h2>Orthodox Liturgics</h2>
            <div className={Stylesheet.Selector}>
                <Calendar
                    onChange={date => lookupService(date)}
                    defaultValue={sessionDate === -1 ? undefined : sessionDate} />
                <div className={Stylesheet.Services}>
                    <p>available services</p>
                    {getServices()}
                </div>
            </div>
        </div>
    )
}

export default Home;