import React, { useState } from 'react';
import Stylesheet from './header.module.css';
import logo from '../../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
import Profile from '../../components/Profile/Profile';
import Button from '../../elements/Button/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import toggleToast from '../../store/actions/toast.actions';
import { ico_code, ico_cog, ico_file_download, ico_font, ico_print, ico_share_alt } from '../../icon';
import { toggleEditor } from '../../store/reducers/editor';
import FontWidget from '../../components/Widgets/Font/Font';
import SettingsWidget from '../../components/Widgets/Settings/Settings';
import Share from '../../components/Widgets/Share/Share';
import Download from '../../components/Widgets/Download/Download';

function Header() {
    const user = useAppSelector(state => state.users);
    const setPreviewer = useAppDispatch();
    const previewer = useAppSelector(state => state.previewer);
    const editor = useAppSelector(state => state.editor);
    const ActiveServiceText = editor.serviceText;
    const ActiveNamespace = editor.settings.namespace;
    const ActiveScript = ActiveNamespace === "service-text"
        ? ActiveServiceText?.text || ""
        : editor.template?.text || "";
    const isPreviewPage = window.location.pathname.match("/view/");
    const location = useLocation();
    const [showShareWidget, setShowShareWidget] = useState(false);
    const [showDownloadWidget, setShowDownloadWidget] = useState(false);
    const [showSettingsWidget, setShowSettingsWidget] = useState(false);
    const [showFontWidget, setShowFontWidget] = useState(false);

    const getPrintWiget = () => {
        var printWindow = window.open('', '', 'height=600,width=1000');
        if (!printWindow || !ActiveScript) {
            toggleToast({ message: "print functionality is not available currently, please try again later.", isError: true })
            return;
        }
        printWindow.document.write(ActiveScript);
        printWindow.document.close();
        printWindow.print();
    }

    return (
        <header className={Stylesheet.Header}>
            <div className={Stylesheet.Logo}>
                <Link to='/'>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className={Stylesheet.Controls} style={{ display: isPreviewPage ? "unset" : "none" }}>
                {user.isLoggedIn && user?.role !== "user"
                    ? <Button
                        primary={!editor.settings.hideEditor}
                        secondary={editor.settings.hideEditor}
                        disabled={previewer.settings.language.length === 2 || window.innerWidth < 500}
                        onClick={_ => setPreviewer(toggleEditor())}>
                        {ico_code}</Button>
                    : null}
                <Button
                    primary={showShareWidget}
                    disabled={previewer.settings.language.length > 1 || editor.settings.namespace === "template"}
                    onClick={_ => setShowShareWidget(current => current ? false : true)}>{ico_share_alt}</Button>
                <Button
                    disabled={(ActiveScript === "" && previewer.settings.language.length === 1) || editor.settings.namespace === "template"}
                    primary={showDownloadWidget}
                    onClick={_ => setShowDownloadWidget(current => current ? false : true)}>{ico_file_download}</Button>
                <Button
                    disabled={ActiveScript === "" || previewer.settings.language.length > 1 || editor.settings.namespace === "template"}
                    onClick={getPrintWiget}>{ico_print}</Button>
                <Button
                    primary={showSettingsWidget}
                    onClick={_ => setShowSettingsWidget(current => current ? false : true)}>{ico_cog}</Button>
                <Button
                    primary={showFontWidget}
                    onClick={_ => setShowFontWidget(current => current ? false : true)}>
                    {ico_font}</Button>
            </div>
            <Profile />
            <FontWidget show={showFontWidget} toggle={() => setShowFontWidget(current => current ? false : true)} />
            <SettingsWidget show={showSettingsWidget} toggle={() => setShowSettingsWidget(current => current ? false : true)} />
            <Share show={showShareWidget} toggle={() => setShowShareWidget(current => current ? false : true)} />
            <Download show={showDownloadWidget} toggle={() => setShowDownloadWidget(current => current ? false : true)} />
        </header>
    )
}

export default Header;