import React, { useEffect, useRef, useState } from 'react';
import Stylesheet from './Code.module.css';
import { convertToJSX } from './Formatter';

export interface Intellisense {
    text: string,
    icon: JSX.Element,
    description: string,
    value: string
}

interface CodeProps {
    schema: "html" | "css" | "javascript",
    onChange?: (value: string) => void,
    onBlur?: (value: string) => void,
    placeholder?: string,
    defaultValue?: string,
    required?: boolean,
    disabled?: boolean,
    spellCheck?: boolean,
    enableLineNumbering?: boolean,
    onIntellisense?: (filter: string) => Array<Intellisense>,
    resize?: "both" | "vertical" | "horizontal" | "none"
}

const Code = (props: CodeProps) => {
    const [lines, setLines] = useState<Array<JSX.Element>>([<code
        onClick={_ => setLineNumber(0)} className={Stylesheet.Line} key={0}>{convertToJSX("<h1>hello world !</h1>", props.schema, Stylesheet)}</code>]);
    const totalLines = useRef(1);
    const lineNumber = useRef(0);
    const columnNumber = useRef(0);
    const [showIntell, setShowIntell] = useState(false);
    const [intellisenseFilter, setIntellisenseFilter] = useState<string>();
    const [intellisense, setIntellisense] = useState<Array<Intellisense>>([]);
    let codeRef: React.RefObject<HTMLDivElement> = React.createRef();

    useEffect(() => {
        if (!props.defaultValue || props.defaultValue === "") {
            setLines([<code onClick={_ => setLineNumber(0)} className={Stylesheet.Line} key={0}>{convertToJSX("<h1>hello world !</h1>", props.schema, Stylesheet)}</code>]);
            setTotalLines(1);
            return;
        }
        let script = new DOMParser().parseFromString(props.defaultValue, "text/html").body.innerHTML;
        // recompile script
        // complie entire script
        let collection: Array<JSX.Element> = [];
        let lines = script.split('\n');
        for (let index = 0; index < lines.length; index++)
            collection.push(getRenderedCode(index + 1, lines[index]))
        setLines(collection);
        if (props.enableLineNumbering) setTotalLines(lines.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue]);

    /**
     * Identify the caret coordinates on the window
     */
    const getCaretPosition = () => {
        let x = 0, y = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
            const selection = window.getSelection();
            if (selection && selection.rangeCount !== 0) {
                const range = selection.getRangeAt(0).cloneRange();
                range.collapse(true);
                const rect = range.getClientRects()[0];
                if (rect) {
                    x = rect.left;
                    y = rect.top;
                }
            }
        }
        return { x, y };
    }
    /**
     * Identify the carets location on the current line
     */
    const getCaretColumn = (element: Node) => {
        let column = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
            const selection = window.getSelection();
            if (selection && selection.rangeCount !== 0) {
                const range = selection.getRangeAt(0);
                const preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                column = preCaretRange.endOffset;
            }
        }
        return column;
    }

    /**
     * Identify the active line number from `Arrow` navigation within the editor
     */
    const getActiveLine = (event: React.KeyboardEvent<HTMLDivElement>) => {
        let codeLineNumberRef = document.getElementById("CodeLineNumber");
        const line = parseInt(codeLineNumberRef?.innerText || "0");
        if (event.key === "ArrowDown") return line + 1;
        else if (event.key === "ArrowUp") return line === 0 ? line : line - 1;
        else return line;
    }

    const getIntellisense = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const key = event.key;
        let intellisenseKey = intellisenseFilter;
        let show = showIntell;
        if (event.code.match('Key')) intellisenseKey = intellisenseFilter + key;
        // identify key
        switch (event.key) {
            case "Backspace":
                intellisenseKey = intellisenseKey?.slice(0, intellisenseKey.length - 1);
                break;
            case "Escape":
            case "Enter":
                show = false;
                intellisenseKey = undefined;
                break;
            default:
                show = true;
                break;
        }
        return {
            key: intellisenseKey || "",
            show: show
        };
    }

    const getUpdatedLine = () => {
        if (window.getSelection !== null) {
            const selection = window.getSelection() as Selection;
            // get active line script
            const element = selection.focusNode?.parentElement?.parentElement;
            const code = element?.innerText;
            // get highlighted syntax
            if (element && code && code?.split('\n').length === 1) {
                // const rendered = formatToString(code, props.schema, Stylesheet);
                // update rendered line
                // element.innerHTML = rendered;
                // console.log(selection.focusNode?.parentElement);
            }
        }
    }

    /**
     * Identify the editor state and set navigation metadata
     */
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // fetch active text and normalize string
        const value = codeRef.current?.innerText.replaceAll(/\u00A0/g, " ") as string;
        const lines = value.split('\n').length - (value.split('\n').pop() === "" ? 1 : 0);
        const column = getCaretColumn(event.currentTarget);
        const position = getCaretPosition();
        // update active line number
        const line = getActiveLine(event);
        const intellisense = getIntellisense(event);
        getUpdatedLine();
        setPosition(position);
        setLineNumber(line);
        setColumnNumber(column);
        setTotalLines(lines);
        setShowIntell(val => column === 0 ? false : val);
        // setIntellisenseFilter(intellisense.key);
        setShowIntell(intellisense.show);
        if (props.onChange) props.onChange(value);
        if (props.onIntellisense && intellisense.key)
            setIntellisense(props.onIntellisense(intellisense.key))
    }

    /**
     * set the active position and intellisense metadata
     */
    const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        //setColumn(getCaretColumn(event.currentTarget));
        setShowIntell(false);
        setIntellisenseFilter(undefined);
    }

    const setLineNumber = (index: number) => {
        const ele = document.getElementById("CodeLineNumber");
        if (ele && ele.innerText !== index.toString()) {
            ele.innerText = index.toString();
            lineNumber.current = index;
        }
    }

    const setTotalLines = (lines: number) => {
        if (lines === totalLines.current) return;
        const ele = document.getElementById("CodeTotalLines");
        const col = document.getElementById("CodeLineNumbers");
        if (col && ele && ele.innerText !== lines.toString()) {
            ele.innerText = lines.toString();
            totalLines.current = lines;
            // update dom
            col.innerHTML = "";
            for (let line = 1; line <= lines; line++) {
                const element = document.createElement("code");
                element.innerText = line.toString();
                col?.appendChild(element);
            }
        }
    }

    const setColumnNumber = (index: number) => {
        const ele = document.getElementById("CodeColumnNumber");
        if (ele && ele.innerText !== index.toString()) {
            ele.innerText = index.toString();
            columnNumber.current = index;
        }
    }

    const setPosition = (coordinates: { x: number; y: number; }) => {
        const ele = document.getElementById("CodeColumnNumber");
        if (ele) {
            ele.style.top = `${coordinates.y + 20}px`;
            ele.style.left = `${coordinates.x}px`;
        }
    }

    const getRenderedCode = (index: number, code: string): JSX.Element => {
        return (
            <code
                onClick={_ => setLineNumber(index)}
                className={Stylesheet.Line}
                key={index + Math.random()}>
                {convertToJSX(code, props.schema, Stylesheet)}</code>
        )
    }

    const onIntellisense = (intellisense: Intellisense, key: string) => {
        if (key !== "Enter" && key !== "Tab" && key !== "Click") return;
        let selection = window.getSelection();
        let element = selection?.focusNode?.parentElement;
        if (element) element.innerHTML += intellisense.value;
        // only recompile changes and embed intellisense suggestions
        // let currentCompiledLines = [...lines];
        // let currentUncompiledLines = value.split('\n');
        // let currentLine = currentUncompiledLines[line - 1];
        // let startPoint = intellisenseFilter ? column - intellisenseFilter.length : column;
        // // update embeded line on unrendered script
        // currentLine = currentLine.slice(0, startPoint)
        //     .concat(intellisense.value, currentLine.slice(column));
        // // replace embeded intellisense filter from script
        // // ............
        // // update embeded lines on rendered script
        // currentCompiledLines[line] = getRenderedCode(line, currentLine);
        // // update active line with embbed intellisense
        // currentUncompiledLines[line - 1] = currentLine;
        // // setUncompiledLines(currentUncompiledLines);
        // setLines(currentCompiledLines);
        // clear active suggestion
        //if (props.onChange) props.onChange(currentUncompiledLines.join('\n'));
    }

    console.log('rendering')

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.CodeContainer}
                onScroll={_ => {
                    setShowIntell(false);
                    setIntellisenseFilter(undefined);
                }}>
                {props.enableLineNumbering
                    ? <div id='CodeLineNumbers' className={Stylesheet.LineNumbers}></div>
                    : null}
                <div
                    className={Stylesheet.Editor}
                    contentEditable
                    suppressContentEditableWarning
                    spellCheck={props.spellCheck}
                    onKeyUp={onKeyDown}
                    ref={codeRef}
                    onMouseUp={onMouseUp}>
                    {lines.map((value) => (value))}
                </div>
            </div>
            <div className={Stylesheet.Metadata}>
                <h6><span id='CodeTotalLines'>1</span> Lines</h6>
                <h6>Ln <span id='CodeLineNumber'>1</span>, </h6>
                <h6>Col <span id='CodeColumnNumber'>1</span></h6>
                <h6>{props.schema}</h6>
            </div>
            <div
                className={Stylesheet.Intellisense}
                id="CodeIntellisense"
                style={{
                    top: '20px',
                    left: '0px',
                    display: showIntell && intellisense.length > 0 ? "unset" : "none"
                }}>
                {intellisense.map((value, index) => (
                    <h5
                        onKeyUp={event => onIntellisense(value, event.key)}
                        onClick={_ => onIntellisense(value, "Click")}
                        key={index + Math.random()}
                        tabIndex={index}>{value.icon}&emsp;{value.text}</h5>
                ))}
            </div>
        </div>
    )
}

export default Code;