import React, { useEffect, useState } from 'react';
import Stylesheet from './Search.module.css';
import useDebounce from '../../hooks/debounce';
import Button from '../Button/Button';
import { ico_search } from '../../icon';

interface AddressProps {
    icon?: JSX.Element,
    className?: string,
    onChange: (value: string) => void,
    placeholder?: string,
    disabled?: boolean,
    required?: boolean,
    loading?: boolean
}

const Search = (props: AddressProps) => {
    const [search, setSearch] = useState("");
    const debouceSearchTerm = useDebounce(search, 300);

    useEffect(() => {
        // lookup address
        props.onChange(debouceSearchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouceSearchTerm]);

    return (
        <div className={Stylesheet.Container}>
            {props?.icon ? <h5 className={Stylesheet.Icon}>{props.icon}</h5> : null}
            <input type="text"
                value={search}
                className={props?.className}
                onChange={event => setSearch(event.target.value)}
                placeholder={props?.placeholder || "enter value"}
                disabled={props?.disabled || false}
                required={props?.required || false} />
            <Button disabled={props.disabled} icon loading={props.loading || false}>{ico_search}</Button>
        </div>
    )
}

export default Search;