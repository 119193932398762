import Stylesheet from './Download.module.css';
import Button from '../../../elements/Button/Button';
import Classnames from 'classnames';
import { ico_file_pdf, ico_file_word } from '../../../icon';
import { useAppSelector } from '../../../store/hooks';
import { ServiceTextInterface } from '../../../store/reducers/editor';

interface DownloadProps {
    show: boolean,
    toggle: () => void
}

const Download = (props: DownloadProps) => {
    const editor = useAppSelector(state => state.editor);
    const previewer = useAppSelector(state => state.previewer);
    const date = editor.settings.namespace === "service-text"
        ? new Date(`${editor.serviceText?.month}-${editor.serviceText?.date}-${editor.serviceText?.year}`).toDateString()
        : "";

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: props.show,
        [Stylesheet['hide-container']]: !props.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: props.show,
        [Stylesheet['hide-overlay']]: !props.show
    })

    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: props.show,
        [Stylesheet['hide-popup']]: !props.show
    })

    const getAvailableServiceTexts = () => {
        const collection: Array<ServiceTextInterface> = [];
        for (const lang of previewer.settings.language) {
            const serviceText = editor.serviceTexts?.find((val) => val.language === lang);
            if (serviceText) collection.push(serviceText);
        }
        return collection;
    }

    return (
        <div className={containerStyle} >
            <div className={overlayStyle}
                onClick={_ => props.toggle()}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Header}>
                    <h3>Download Service</h3>
                    <p>select which format you would like to download the service in.</p>
                </div>
                <div className={Stylesheet.WidgetControls}>
                    {getAvailableServiceTexts().map((val, index) => (
                        val?.pdfURL
                            ? <a
                                href={val?.pdfURL}
                                target="_blank"
                                rel="noreferrer"
                                key={index + Math.random()}
                                download={`${val.service_type} ${date}.pdf`}
                                className={Stylesheet.Link}>
                                {ico_file_pdf}&emsp;Download PDF ({val.language})</a>
                            : <Button
                                disabled
                                key={index + Math.random()}
                                secondary>
                                {ico_file_pdf}&emsp;PDF Unavailable in ({val.language || ""})</Button>
                    ))}
                    <div className={Stylesheet.Line}></div>
                    {getAvailableServiceTexts().map((val, index) => (
                        val?.rtfURL
                            ? <a
                                href={val?.rtfURL}
                                download={`${val.service_type} ${date}.rtf`}
                                target="_blank"
                                rel="noreferrer"
                                key={index + Math.random()}
                                className={Stylesheet.Link}>
                                {ico_file_word}&emsp;Download RTF ({val.language})</a>
                            : <Button
                                disabled
                                key={index + Math.random()}
                                secondary>
                                {ico_file_word}&emsp;RTF Unavailable ({val.language || ""})</Button>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default Download;