import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

// Define a type for the slice state
export interface ToastState {
    icon?: string,
    message: string,
    isError: boolean,
    show?: boolean
}
// Define the initial state using that type
const initialState: ToastState = {
    icon: undefined,
    message: "",
    isError: false,
    show: false
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<ToastState>) => {
            return {...action.payload, show: true};
        },
        hide: (state) => {
            return {...state, show: false};
        }
    }
})

export const { show, hide } = toastSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectToast = (state: RootState) => state.toast;

export default toastSlice.reducer;