import React from 'react';
import Stylesheet from './Modal.module.css';
import Classnames from 'classnames';
import { ico_cog, ico_times } from '../../icon';
import Button from '../../elements/Button/Button';
import { useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/actions/modal.actions';
import Settings from '../../containers/Settings/Settings';

function Modal() {
    const modal = useAppSelector(state => state.modal);

    return (
        <div className={Classnames(
            Stylesheet.Container, {
            [Stylesheet['show-container']]: modal.show,
            [Stylesheet['hide-container']]: !modal.show
        })}>
            <div className={Classnames(
                Stylesheet.Overlay, {
                [Stylesheet['show-overlay']]: modal.show,
                [Stylesheet['hide-overlay']]: !modal.show
            })} onClick={_ => closeModal()}></div>
            <div className={Classnames(
                Stylesheet.Modal, {
                [Stylesheet['show-modal']]: modal.show,
                [Stylesheet['hide-modal']]: !modal.show
            })}>
                <div className={Stylesheet.Header}>
                    <h5>{modal.type === "settings" ? ico_cog : null}
                        &emsp;{modal.type === "settings" ? "preferences" : null}</h5>
                    <div className={Stylesheet.Control}>
                        <Button icon onClick={_ => closeModal()}>
                            {ico_times}
                        </Button>
                    </div>
                </div>
                <div className={Stylesheet.Container}>
                    {modal.type === "settings" ? <Settings /> : null}
                </div>
            </div>
        </div>
    )
}

export default Modal;