import React, { useEffect, useRef, useState } from 'react';
import Button from '../../elements/Button/Button';
import { ico_book, ico_cog, ico_css, ico_eye, ico_html, ico_js, ico_pen_alt, ico_play, ico_save } from '../../icon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setServiceText, setSettings, setTab, setTemplate, SourceInterface, togglePreviwer } from '../../store/reducers/editor';
import Stylesheet from './Editor.module.css';
import Classnames from 'classnames';
import util from '@enigma/core/utilities';
import { setActiveSources } from '../../store/reducers/previewer';
import Code, { Intellisense } from '../../components/Code/Code';
import { firestore } from '@enigma/core/services';
import toggleToast from '../../store/actions/toast.actions';
import { showModal } from '../../store/actions/modal.actions';


const Editors = () => {
    const editor = useAppSelector(state => state.editor);
    const setEditor = useAppDispatch();
    const ActiveServiceText = editor.serviceText;
    const ActiveNamespace = editor.settings.namespace;
    const ActiveServiceType = ActiveNamespace === "service-text"
        ? ActiveServiceText?.service_type || ""
        : editor.template?.serviceType || "";
    const ActiveScript = ActiveNamespace === "service-text"
        ? ActiveServiceText?.text || `<!DOCTYPE html><html><head><link href="https://typikonassets.web.app/styles/vespers.css" rel="stylesheet" type="text/css"/></head><body></body></html>`
        : editor.template?.text || `<!DOCTYPE html><html><head><link href="https://typikonassets.web.app/styles/vespers.css" rel="stylesheet" type="text/css"/></head><body></body></html>`;
    const ActiveTab = editor.settings.tab;
    const [sources, setSources] = useState<Array<SourceInterface>>([]);
    const database = new util.database("orthodoxliturgikon");
    const codeChanges = useRef(ActiveScript);

    useEffect(() => {
        let sources = database.table('sources');
        sources.where('services', "array-contains", ActiveServiceType)
            .get().then(snap => {
                let collection: Array<SourceInterface> = [];
                for (const source of snap.getAll())
                    collection.push({ ...source.data, id: source.id });
                setSources(collection);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderPreviwer = (html: string) => {
        // perform preprocessing
        let activeSources: Array<SourceInterface> = [];
        // process all embeded sources
        for (const source of sources)
            if (html.match(`<${source.shortName} />`))
                activeSources.push(source);
        // update list of active sources
        setEditor(setActiveSources(activeSources));

        if (ActiveNamespace === "service-text" && ActiveServiceText)
            // update service text script
            setEditor(setServiceText({ ...ActiveServiceText, text: html }))
        else
            // update template script
            setEditor(setTemplate({ ...editor.template, text: html }));
    }

    const saveActiveScript = () => {
        if (editor.settings.namespace === "service-text") {
            // save service text
            if (!editor.serviceText) return;
            if (editor.serviceText.id)
                firestore.collection('service-texts')
                    .doc(editor.serviceText.id)
                    .set(editor.serviceText)
                    .then(_ => toggleToast({ message: `service text successfully updated`, isError: false }))
            else
                firestore.collection('service-texts')
                    .add(editor.serviceText)
                    .then(_ => toggleToast({ message: `service text successfully created`, isError: false }))
        } else {
            // save template
            if (editor.template)
                firestore.collection('templates').doc(editor.template.id)
                    .update(editor.template).then(_ => {
                        toggleToast({
                            message: `template successfully updated`,
                            isError: false
                        })
                    })
        }
    }

    // extract body from html script
    const pretranslateHTML = () => {
        return ActiveScript.split("<body>")[1]?.split("</body>")[0];
    }

    // extract style from html script
    const pretranslateCSS = () => {
        if (ActiveScript.match('<style>'))
            return (ActiveScript.split('<style>')[1]?.split("</style>")[0]);
        else return "";
    }

    // extract javsscript from html script
    const pretranslateJS = () => {
        if (ActiveScript.match('<script>'))
            return ActiveScript.split('<script>')[1]?.split("</script>")[0];
        else return "";
    }

    // update body of html script
    const postTranslateHTML = (text: string) => {
        return ActiveScript.replace(`<body>${ActiveScript.split("<body>")[1].split("</body>")[0]}</body>`, `<body>${text}</body>`);
    }

    // update styles of html script
    const postTranslateCSS = (text: string) => {
        if (ActiveScript.match('<style>'))
            return ActiveScript.replace(`<style>${ActiveScript.split("<style>")[1].split("</style>")[0]}</style>`, `<style>${text}</style>`);
        else
            return ActiveScript.split('</head>').join(`<style>${text}</style></head>`);
    }

    // update javascript of html script
    const postTranslateJS = (text: string) => {
        if (ActiveScript.match('<script>'))
            return ActiveScript.replace(`<body>${ActiveScript.split("<script>")[1].split("</script>")[0]}</body>`, `<script>${text}</script>`);
        else
            return ActiveScript.split('</body>').join(`<script>${text}</script></body>`);
    }

    const setIntellisense = (filter: string): Array<Intellisense> => {
        let collection: Array<Intellisense> = [];
        for (const source of sources.filter(({ shortName }) => {
            //console.log(shortName.match(filter), shortName, filter)
            return shortName.match(filter) ? true : false
        }))
            collection.push({
                text: source.shortName,
                description: source.text,
                icon: ico_book,
                value: `<${source.shortName} />`
            });
        return collection;
    }

    const onChange = (code: string) => codeChanges.current = code;

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['HideEditor']]: editor.settings.hideEditor,
            [Stylesheet['HidePreviwer']]: editor.settings.hidePreviwer,
            [Stylesheet['DisableEditor']]: editor.settings.disableEditor
        })}>
            <div className={Stylesheet.Controls}>
                <Button
                    secondary
                    onClick={_ => renderPreviwer(codeChanges.current)}>
                    {ico_play}</Button>
                <Button
                    primary={!editor.settings.hidePreviwer}
                    secondary={editor.settings.hidePreviwer}
                    onClick={_ => setEditor(togglePreviwer())}>
                    {ico_eye}</Button>
                <Button
                    onClick={saveActiveScript}>
                    {ico_save}</Button>
                <Button
                    primary={editor.settings.namespace === "service-text"}
                    onClick={_ => setEditor(setSettings({
                        namespace: ActiveNamespace === "service-text"
                            ? "template" : "service-text"
                    }))}>{ico_pen_alt}</Button>
                {editor.settings.disableHTML ? null :
                    <Button
                        primary={ActiveTab === "html"}
                        onClick={_ => setEditor(setTab("html"))}>
                        {ico_html}</Button>
                }
                {editor.settings.disableCSS ? null :
                    <Button
                        primary={ActiveTab === "css"}
                        onClick={_ => setEditor(setTab("css"))}>
                        {ico_css}</Button>
                }
                {editor.settings.disableJS ? null :
                    <Button
                        primary={ActiveTab === "javascript"}
                        onClick={_ => setEditor(setTab("javascript"))}>
                        {ico_js}</Button>
                }
                <Button
                    onClick={_ => showModal("settings")}>
                    {ico_cog}</Button>
            </div>
            <div className={Stylesheet.Textarea}>
                {ActiveTab === "html"
                    ? <Code
                        enableLineNumbering
                        schema={"html"}
                        spellCheck={false}
                        defaultValue={pretranslateHTML()}
                        onChange={value => onChange(postTranslateHTML(value))}
                        onIntellisense={setIntellisense}
                        placeholder='edit service text for template. i.e <h1 title>Hello World !</h1>' />
                    : null}
                {ActiveTab === "css"
                    ? <Code
                        enableLineNumbering
                        schema={"css"}
                        spellCheck={false}
                        defaultValue={pretranslateCSS()}
                        onChange={value => onChange(postTranslateCSS(value))}
                        placeholder='edit service style for template. i.e .title { color: red }' />
                    : null}
                {ActiveTab === "javascript"
                    ? <Code
                        enableLineNumbering
                        schema={"javascript"}
                        spellCheck={false}
                        defaultValue={pretranslateJS()}
                        onChange={value => onChange(postTranslateJS(value))}
                        placeholder='edit service logic for template. i.e console.log("hello world")' />
                    : null}
            </div>
        </div>
    )
}

export default Editors;