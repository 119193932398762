import { firestore, user } from '@enigma/core/services';
import React, { useEffect, useState } from 'react';
import Template from '../../../components/Template/Template';
import Button from '../../../elements/Button/Button';
import Search from '../../../elements/Search/Search';
import { ico_book, ico_plus } from '../../../icon';
import { toggleMessageBox } from '../../../store/actions/messageBox.actions';
import toggleToast from '../../../store/actions/toast.actions';
import { useAppSelector } from '../../../store/hooks';
import { TemplateInterface } from '../../../store/reducers/editor';
import { MessageBoxResponses, MessageBoxTypes } from '../../../store/reducers/messageBox';
import { UserState } from '../../../store/reducers/users';
import Stylesheet from './Templates.module.css';

const Templates = () => {
    const userMeta = useAppSelector(state => state.users);
    const [templates, setTemplates] = useState<Array<TemplateInterface>>([])
    const [unverifiedTemplates, setUnverifiedTemplates] = useState<Array<TemplateInterface>>([])
    const [result, setResult] = useState<Array<UserState> | null>();
    const [page, setPage] = useState(1);
    const TEMPLATE_PER_PAGE = 9;
    // TEMP CODE
    useEffect(() => {
        if (!userMeta.email) return;
        // fetch all users from collection
        firestore.collection('templates')
            .onSnapshot(snap => {
                let collection: Array<TemplateInterface> = [];
                for (const doc of snap.docs)
                    collection.push({ ...doc.data(), id: doc.id, collection: "templates" });
                setTemplates(collection);
            })
        firestore.collection('unverifiedTemplates')
            .onSnapshot(snap => {
                let collection: Array<TemplateInterface> = [];
                for (const doc of snap.docs)
                    collection.push({ ...doc.data(), id: doc.id, collection: "unverifiedTemplates" });
                setUnverifiedTemplates(collection);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const collection = () => {
        if (templates.length === 0 && unverifiedTemplates.length === 0) return (
            <div className={Stylesheet.NoTemplates}>
                <h1>{ico_book}</h1>
                <h3>no templates to show</h3>
                <h4>
                    To begin working with templates click the plus button above, it will guide you
                    through making your first template.
                </h4>
            </div>
        )
        if (result) {
            if (result.length > 0)
                return (
                    <React.Fragment>
                        {result.map((user, index) => (
                            <Template {...templates.find(({ id }) => id === user.id)}
                                key={index + Math.random()} />
                        ))}
                    </React.Fragment>
                )
            return (
                <div className={Stylesheet.NoTemplates}>
                    <h1>{ico_book}</h1>
                    <h3>no templates match your search</h3>
                    <h4>
                        To refine your search be as specific as possible, as partial matches
                        are also considered during filtering, the templates type.
                    </h4>
                </div>
            )
        }
        let collection = [...templates].slice((page * TEMPLATE_PER_PAGE) - TEMPLATE_PER_PAGE, page * TEMPLATE_PER_PAGE);
        return (
            <React.Fragment>
                {unverifiedTemplates.map((user, index) => (
                    <Template {...user} key={index + Math.random()} />
                ))}
                {collection.map((user, index) => (
                    <Template {...user} key={index + Math.random()} />
                ))}
            </React.Fragment>
        )
    }

    const pages = () => {
        if (templates.length <= TEMPLATE_PER_PAGE) return null;
        let collection = [];
        let totalPages = Math.ceil(templates.length / TEMPLATE_PER_PAGE);
        for (let index = 1; index <= totalPages; index++)
            collection.push(<Button primary={index === page} key={index} onClick={_ => setPage(index)}>{index.toString()}</Button>)
        return collection;
    }

    const filter = (search: string) => {
        search = search.toLocaleLowerCase()
        if (search !== "")
            setResult(templates.filter((current) => {
                if (current.name?.match(search))
                    return true;
                return false;
            }))
        else setResult(null);
    }

    const createTemplate = async () => {
        const response = await toggleMessageBox({
            title: "create new template",
            prompt: "please enter the name of the template to create, titles are generally short (at most 2 words).",
            type: MessageBoxTypes.prompt
        });

        if (response !== MessageBoxResponses.cancle) {
            if (!userMeta.jurisdiction) {
                toggleToast({
                    message: "please select a jurisdiction in the settings tab before creating templates.",
                    isError: true
                })
                return;
            }
            const template: TemplateInterface = {
                name: response,
                creator: user.uid,
                version: 0.1,
                verified: false,
                jurisdiction: userMeta.jurisdiction,
                language: "EN"
            }
            // initilize new template
            firestore.collection(userMeta.role === "reviewer" ? 'unverifiedTemplates' : "templates")
                .add(template).then(_ => toggleToast({ message: `'${response}' template created successfully.`, isError: false }))
                .catch(_ => toggleToast({ message: `'${response}' template could not be created`, isError: true }))
        }
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Search}>
                <Button icon secondary onClick={createTemplate}>{ico_plus}</Button>
                <Search onChange={value => filter(value)}
                    placeholder='search template title or publish date' />
            </div>
            <div className={Stylesheet.Paganation}>
                {pages()?.map((value) => (value))}
            </div>
            <div className={Stylesheet.Collection}>
                {collection()}
            </div>
        </div>
    )
}

export default Templates;