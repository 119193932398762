import React from 'react';
import Stylesheet from './Font.module.css';
import Button from '../../../elements/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setFontSize } from '../../../store/reducers/previewer';
import Classnames from 'classnames';

interface FontProps {
    show: boolean,
    toggle: () => void
}

const Font = (props: FontProps) => {
    const previewer = useAppSelector(state => state.previewer);
    const setPreviewer = useAppDispatch();

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: props.show,
        [Stylesheet['hide-container']]: !props.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: props.show,
        [Stylesheet['hide-overlay']]: !props.show
    })

    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: props.show,
        [Stylesheet['hide-popup']]: !props.show
    })

    return (
        <div className={containerStyle} >
            <div className={overlayStyle}
                onClick={_ => props.toggle()}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Header}>
                    <h3>adjust font size</h3>
                    <p>select a font size that makes the text easier to read.</p>
                </div>
                <div className={Stylesheet.WidgetControls}>
                    <Button
                        primary={previewer.fontSize === 10}
                        secondary={previewer.fontSize !== 10}
                        onClick={_ => (setPreviewer(setFontSize(10)))}>small font</Button>
                    <Button
                        primary={previewer.fontSize === 12 || !previewer.fontSize}
                        secondary={previewer.fontSize !== 12 && previewer.fontSize !== undefined}
                        onClick={_ => (setPreviewer(setFontSize(12)))}>medium font</Button>
                    <Button
                        primary={previewer.fontSize === 15}
                        secondary={previewer.fontSize !== 15}
                        onClick={_ => (setPreviewer(setFontSize(15)))}>large font</Button>
                    <Button
                        primary={previewer.fontSize === 20}
                        secondary={previewer.fontSize !== 20}
                        onClick={_ => (setPreviewer(setFontSize(20)))}>extra large font</Button>
                </div>
            </div>
        </div >
    )
}

export default Font;