import React from 'react';
import Stylesheet from './Image.module.css';
import Classnames from 'classnames';

interface SpinnerProps {
    show?: boolean
}

function Spinner(props: SpinnerProps) {

    return (
        <div className={Classnames(
            Stylesheet.Container, {
            [Stylesheet.Show]: props.show || true,
            [Stylesheet.Hide]: !props.show || false
        })}>
        </div>
    )
}

export default Spinner;