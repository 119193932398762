import React from 'react';
import Stylesheet from './Button.module.css';
import Classnames from 'classnames';
import { ico_spinner } from '../../icon';

interface ButtonProps {
    link?: boolean,
    primary?: boolean,
    secondary?: boolean,
    warning?: boolean,
    error?: boolean,
    icon?: boolean,
    minimal?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disabled?: boolean,
    title?: string,
    children?: Array<JSX.Element | string> | JSX.Element | string,
    type?: "button" | "submit",
    loading?: boolean,
    className?: string,
    ariaLabel?: string
}

function Button(props: ButtonProps) {

    return (
        <button className={Classnames(Stylesheet.Button, props.className, {
            [Stylesheet['Link']]: props?.link || false,
            [Stylesheet['Primary']]: props?.primary || false,
            [Stylesheet['Secondary']]: props?.secondary || false,
            [Stylesheet['Warning']]: props?.warning || false,
            [Stylesheet['Error']]: props?.error || false,
            [Stylesheet['Default']]: (!props?.primary || false) && (!props?.secondary || false),
            [Stylesheet['Icon']]: props?.icon || false,
            [Stylesheet['Minimal']]: props?.minimal || false,
        })}
            onClick={props.onClick} type={props?.type || 'button'}
            aria-label={props.ariaLabel}
            disabled={(props?.disabled || false) || (props?.loading || false)}
            title={props?.title || ''}>
            {props.children}
            {props?.loading
                ? <h5 className={Stylesheet.Spinner}>{ico_spinner}</h5>
                : null}
        </button>
    )
}

export default Button;