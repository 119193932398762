import React from 'react';


export const convertToJSX = (
    code: string,
    schema: "html" | "css" | "javascript",
    stylesheet: { readonly [key: string]: string; }
) => {
    // replace trailling spaces with renderable spaces
    let spaces = code.slice(0, code.search(/\S|$/)).split(' '); spaces.pop();
    // remove leading whitespcaes
    code = code.replace(/^\s+/g, '');
    // replace unicode charactors
    code = code.replaceAll(/\u00A0/g, " ");

    switch (schema) {
        case "html":
            return convertToHTMLJSX(code, spaces.length, stylesheet);
        case "css":
            return convertToCSSJSX(code, spaces.length, stylesheet);
        default:
            return convertToJavascriptJSX(code, spaces.length, stylesheet);
    }
}

export const formatToString = (
    code: string,
    schema: "html" | "css" | "javascript",
    stylesheet: { readonly [key: string]: string; }
) => {
    // replace trailling spaces with renderable spaces
    let spaces = code.slice(0, code.search(/\S|$/)).split(' '); spaces.pop();
    // remove leading whitespcaes
    code = code.replace(/^\s+/g, '');
    // replace unicode charactors
    code = code.replaceAll(/\u00A0/g, " ");

    switch (schema) {
        case "html":
            return convertToHTMLStr(code, spaces.length, stylesheet);
        case "css":
            return convertToCSSStr(code, spaces.length, stylesheet);
        default:
            return convertToJavascriptStr(code, spaces.length, stylesheet);
    }
}

const convertToHTMLStr = (
    code: string,
    spaces: number,
    Stylesheet: { readonly [key: string]: string; }
) => {
    // identify tag(s)
    let identifiedTags = code.match(/(<([^>]+)>)/gi);
    for (let snippet of identifiedTags || []) {
        const tag = snippet.split("<")[1].split(" ")[0].replace('>', "").replace('/', "");
        let attributes = snippet
            .replace(`<${tag}`, "")
            .replace(`</${tag}`, "")
            .replace('>', "")
            .replace(' =', '=')
            .replace('= ', '=')
            .split(' '); attributes.splice(0, 1)
        let render = "";
        if (snippet[1] === "/") {
            render += `<span class='${Stylesheet["html-fmtr-t-eo"]}'></</span>`;
            render += `<span class="${Stylesheet["html-fmtr-t"]}">${tag}</span>`;
            render += `<span class='${Stylesheet["html-fmtr-t-ee"]}'>></span>`;
        } else {
            render += `<span class='${Stylesheet["html-fmtr-t-eo"]}'><</span>`;
            render += `<span class="${Stylesheet["html-fmtr-t"]}">${tag}</span>`;
            for (const value of attributes) {
                render += `&nbsp;<span class='${Stylesheet["html-fmtr-t-p"]}'>${value.split('=')[0]}</span>`;
                render += `<span class='${Stylesheet["html-fmtr-t-ps"]}'>=</span>`;
                render += `<span class='${Stylesheet["html-fmtr-t-pv"]}'>${value.split('=')[1]}</span>`;
            }
            render += `<span class='${Stylesheet["html-fmtr-t-ee"]}'>></span>`;
        }
        // replace tag with updated format
        code = code.replace(snippet, render);
        // add trailling spaces
        for (let count = 0; count < spaces; count++) code = /\u00A0/ + code;
    }
    return code;
}
const convertToCSSStr = (code: string, spaces: number, stylesheet: { readonly [key: string]: string; }) => code;
const convertToJavascriptStr = (code: string, spaces: number, stylesheet: { readonly [key: string]: string; }) => code;

const convertToHTMLJSX = (
    code: string,
    spaces: number,
    Stylesheet: { readonly [key: string]: string; }
) => {

    const renderTag = (snippet: string) => {
        const tag = snippet.split("<")[1].split(" ")[0].replace('>', "").replace('/', "");
        let attributes = snippet
            .replace(`<${tag}`, "")
            .replace(`</${tag}`, "")
            .replace('>', "")
            .replace(' =', '=')
            .replace('= ', '=')
            .split(' '); attributes.splice(0, 1)
        if (snippet[1] === "/") {
            return (
                <React.Fragment>
                    <span className={Stylesheet["html-fmtr-t-eo"]}>{"</"}</span>
                    <span className={Stylesheet["html-fmtr-t"]}>{tag}</span>
                    <span className={Stylesheet["html-fmtr-t-ee"]}>{">"}</span>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <span className={Stylesheet["html-fmtr-t-eo"]}>{"<"}</span>
                    <span className={Stylesheet["html-fmtr-t"]}>{tag}</span>
                    {attributes.length > 0 ? <React.Fragment>&nbsp;</React.Fragment> : null}
                    {attributes.map((value, index) => (
                        <React.Fragment key={index + Math.random()}>
                            <span className={Stylesheet["html-fmtr-t-p"]}>{value.split('=')[0]}</span>
                            <span className={Stylesheet["html-fmtr-t-ps"]}>=</span>
                            <span className={Stylesheet["html-fmtr-t-pv"]}>{value.split('=')[1]}</span>
                            {attributes.length > 0 && attributes.length - 1 !== index
                                ? <React.Fragment>&nbsp;</React.Fragment>
                                : null}
                        </React.Fragment>
                    ))}
                    <span className={Stylesheet["html-fmtr-t-ee"]}>{">"}</span>
                </React.Fragment>
            )
        }
    }
    // identify tag(s)
    let tags: Array<JSX.Element> = [];
    let identifiedTags = code.match(/(<([^>]+)>)/gi);
    for (let tag of identifiedTags || []) {
        code = code.replace(tag, "$_$");
        tags.push(renderTag(tag));
    }
    let children = code.split('$_$');
    if (children[0] === '') children.splice(0, 1);
    if (tags.length === 0) return <React.Fragment>{code}</React.Fragment>;
    if (tags.length === 1) return <React.Fragment>{tags.pop()}</React.Fragment>;
    return <React.Fragment>
        {children.map((value, index) => (
            <React.Fragment key={index + Math.random()}>
                {tags[index]}{value}
            </React.Fragment>
        ))}
    </React.Fragment>;
}

const convertToCSSJSX = (code: string, spaces: number, stylesheet: { readonly [key: string]: string; }) => <React.Fragment>{code}</React.Fragment>
const convertToJavascriptJSX = (code: string, spaces: number, stylesheet: { readonly [key: string]: string; }) => <React.Fragment>{code}</React.Fragment>