import React from 'react';
import { useAppSelector } from '../../store/hooks';
import Stylesheet from './Profile.module.css';
import DefaultUser from '../../assets/user-default.jpg';
import Image from '../../elements/Image/Image';
import Button from '../../elements/Button/Button';
import { useDispatch } from 'react-redux';
import { show } from '../../store/reducers/auth';
import Menu from '../../containers/Menu/Menu';
import { toggleMenu } from '../../store/reducers/app';
import { useLocation } from 'react-router-dom';

const Profile = () => {
    const user = useAppSelector(state => state.users)
    const setAuth = useDispatch();
    const setApp = useDispatch();
    const location = useLocation();

    const signup = () => {
        setAuth(show({
            title: "sign up",
            show: true,
            type: "sign-up",
            providers: ["facebook", "google"]
        }))
    }

    const signin = () => {
        setAuth(show({
            title: "sign in",
            show: true,
            type: "sign-in",
            providers: ["facebook", "google"]
        }))
    }

    if (!user.isLoggedIn)
        return (
            <div className={Stylesheet.AuthControls} style={{ marginLeft: window.location.pathname.match("/view/") ? "unset" : "auto" }}>
                <Button primary onClick={_ => signin()}>log in</Button>
                <Button onClick={_ => signup()}>sign up</Button>
            </div>
        )

    return (
        <React.Fragment>
            <div className={Stylesheet.Container}
                onClick={_ => setApp(toggleMenu())} style={{ marginLeft: window.location.pathname.match("/view/") ? "unset" : "auto" }}>
                <div className={Stylesheet.Photo} >
                    {user.photoURL
                        ? <Image src={user.photoURL} alt='user profile' />
                        : <Image src={DefaultUser} alt='user profile default' />}
                </div>
            </div>
            <Menu />
        </React.Fragment>
    )
}

export default Profile;