import React, { useEffect, useState } from 'react';
import { ico_envelope } from '../../icon';
import Stylesheet from './Email.module.css';

interface EmailProps {
    icon?: boolean,
    domain?: string,
    onBlur?: (value: string) => void,
    onChange?: (value: string) => void,
    placeholder?: string,
    defaultValue?: string,
    required?: boolean,
    disabled?: boolean
}

const Email = (props: EmailProps) => {
    const [value, setValue] = useState("")
    useEffect(() => {
        if (props?.defaultValue && value !== props.defaultValue)
            setValue(props.defaultValue || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue]);
    const onBlur = (value: string) => {
        if (props?.domain && value !== "" && props.onBlur)
            value = `${value.split('@')[0]}@${props.domain}`;
        if (props.onBlur) props.onBlur(value);
    }
    const onChange = (value: string) => {
        setValue(value);
        if (props.onChange) props.onChange(value);
    }
    return (
        <div className={Stylesheet.Container}>
            {props?.icon ? <h5 className={Stylesheet.Icon}>{ico_envelope}</h5> : null}
            <input
                onBlur={event => onBlur(event.target.value)}
                onChange={event => onChange(event.target.value)}
                placeholder={props?.placeholder || 'you@example.com'}
                type="email"
                value={value}
                disabled={props.disabled}
                required={props?.required || false} />
        </div>
    )
}

export default Email;