import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './reducers/users';
import toastReducer from './reducers/toast';
import modalReducer from './reducers/modal';
import popupRecuer from './reducers/popup';
import messageBoxReducer from './reducers/messageBox';
import authReducer from './reducers/auth';
import editorReducer from './reducers/editor';
import previewerReducer from './reducers/previewer';
import appReducer from './reducers/app';

const store = configureStore({
    reducer: {
        users: usersReducer,
        toast: toastReducer,
        modal: modalReducer,
        popup: popupRecuer,
        messageBox: messageBoxReducer,
        auth: authReducer,
        editor: editorReducer,
        previewer: previewerReducer,
        app: appReducer
    },
})

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;//