import React, { useEffect, useState } from 'react';
import Stylesheet from './Image.module.css';
import { storage } from '@enigma/core/services';
import Spinner from '../../components/Spinner/Image/Image';
import Default from '../../assets/user-default.jpg';
import File from '../File/File';

interface ImageProps {
    src: string,
    alt: string,
    cache?: boolean,
    destination?: string,
    metadata?: string,
    onUpload?: (taskState: any) => void,
    onEdited?: (path: string) => void,
    accept?: Array<"image/jpeg" | "image/jpeg" | "image/png">,
    editable?: boolean,
    disableUpdateBtn?: boolean
}

const Image = (props: ImageProps) => {
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState(Default);
    const [src, setSrc] = useState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => classifyImageURL(), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => classifyImageURL(), [props])
    /**
     * determins image url nature.
     * if image is a relative path, it is downloaded from cloud storage bucket then rendered.
     */
    const classifyImageURL = function () {
        if (props.src === src) return;
        const url = props.src.split('/')[0];
        const staticURL = url === 'data:image' || url === '' || url.match('http');
        // check if image refference is static or relative
        // relative paths require downloading image from 
        // firebase cloud storage before loading can begin
        if (!staticURL) {
            // get downloadable image from cloud
            const cache = props?.cache ? props.cache : false;
            storage.getObjectURL(props.src, cache).then(imageURL => {
                setLoading(false);
                setImg(imageURL);
                setSrc(props.src);
            })
        } else {
            setLoading(false);
            setImg(props.src);
            setSrc(props.src);
        }
    }

    const update = async function (images: FileList) {
        const image = images.item(0);
        if (!image) return;
        // show cover preview
        setLoading(true);
        setImg(URL.createObjectURL(image));
        // update image to destination
        const url = props.src.split('/')[0];
        const staticURL = url === 'data:image' || url === '' || url.match("http");
        // delete old image from firebase cloud storage
        if (!props.destination) throw new Error("destination path required for image updates");
        if (!staticURL) await storage.delete(props.src);
        // replace image
        const path = await storage
            .uploadFile(image, props.destination, props.metadata || {}, props?.onUpload)
        // image updated
        setLoading(false);
        if (props?.onEdited) props.onEdited(path);
    }

    if (props.editable && props.accept) return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Loading}>
                <Spinner show={loading} />
            </div>
            <img src={img} alt={props.alt} />
            <div className={Stylesheet.Update}>
                <File accept={props.accept} title=' '
                    onChange={update} 
                    disabled={props.disableUpdateBtn} />
            </div>
        </div>
    );
    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Loading}>
                <Spinner show={loading} />
            </div>
            <img src={img}
                alt={props.alt} />
        </div>
    )
}

export default Image;