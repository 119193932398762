import React, { useState } from 'react';
import Button from '../../elements/Button/Button';
import { ico_check, ico_eye } from '../../icon';
import toggleToast from '../../store/actions/toast.actions';
import { SourceInterface } from '../../store/reducers/editor';
import Stylesheet from './Source.module.css';


const Source = (props: SourceInterface & { active?: boolean }) => {
    const [showText, setShowText] = useState(false);
    const copyToClipboard = () => {
        /* Get the text field */
        var copyText = document.createElement("input");
        copyText.value = `<${props.shortName} />`;
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        /* Alert the copied text */
        toggleToast({ message: "source tag coppied to clipboard", isError: false })
    }
    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Header}>
                {props.active
                    ? <div className={Stylesheet.Active}>{ico_check}</div>
                    : null}
                <p className={Stylesheet.Tag} onClick={copyToClipboard}>{`<${props.shortName} />`}</p>
                <Button
                    icon
                    primary={showText}
                    secondary={!showText}
                    onClick={_ => setShowText(value => value ? false : true)}>
                    {ico_eye}</Button>
            </div>
            {showText ? <p className={Stylesheet.Name}>{props.name}</p> : null}
            {showText ? <p className={Stylesheet.Text}>"{props.text}"</p> : null}
        </div>
    )
}

export default Source;