import React from 'react';
import Stylesheet from './File.module.css';
import toggleToast from '../../store/actions/toast.actions';

interface FileProps {
    accept: Array<string>,
    onChange?: (collection: FileList) => void,
    title?: string,
    children?: JSX.Element,
    icon?: JSX.Element,
    disabled?: boolean
}

const File = (props: FileProps) => {

    const accept = () => {
        let collection = [];
        // check if current file type is acceptable
        for (const accept of props.accept) {
            switch (accept) {
                case 'application/word':
                    collection.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                    break;
                case 'application/powerpoint':
                    collection.push('application/vnd.openxmlformats-officedocument.presentationml.presentation');
                    break;
                case 'application/msexcel':
                    collection.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    break;
                case 'application/mscsv':
                    collection.push('application/vnd.ms-excel');
                    break;
                default:
                    collection.push(accept);
                    break;
            }
        }
        return collection.join(',');
    }

    const validate = (collection: FileList | null) => {
        if (collection === null) return;
        if (collection.length > 0) {
            if (!props?.accept) {
                // no file reistrictions set
                if (props?.onChange) 
                    props.onChange(collection);
                return;
            }
             // collection of accepted files from selected
             let files = [];
            // cycle through each selected file
            for (let index = 0; index < collection.length; index++) {
                const file = collection.item(index);
                if (!file) continue;
                // regex for file type validation
                let accept = null;
                // set simpiifed accept critera for current file
                switch (file.type) {
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        accept = 'application/word';
                        break;
                    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                        accept = 'application/powerpoint';
                        break;
                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        accept = 'application/msexcel';
                        break;
                    case 'application/vnd.ms-excel':
                        accept = 'application/mscsv';
                        break;
                    default:
                        accept = file.type;
                        break;
                }
                // check if current file type is acceptable
                for (const type of props.accept) {
                    if (accept === type) {
                        if (props?.onChange) 
                            props.onChange(collection);
                        files.push(file);
                        break;
                    }
                }
            }
            if (files.length === 0) {
                toggleToast({message: 'file types not allowed', isError: true});
            } else if (files.length !== collection.length) {
                toggleToast({message: 'some files were not accepted', isError: true});
            }
        }
    }

    return (
        <div className={Stylesheet.Container}>
                {props.children
                ? <h6 className={Stylesheet.Title}>
                {props.title}</h6>: null}
                <div className={Stylesheet.Fieldset}>
                    {props?.icon
                    ? <div className={Stylesheet.Label}>
                    <h2>{props.icon}</h2></div>: null}
                    <div className={Stylesheet.Input}>
                        <input 
                        onChange={event => validate(event.target.files)}
                        type='file'
                        accept={accept()}
                        disabled={props?.disabled}
                        title={props.title || "choose a file"} />
                    </div>
                </div>
            </div>
    )
}

export default File;