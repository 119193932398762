import React, { useState } from 'react'
import Stylesheet from './MessageBox.module.css';
import Classnames from 'classnames';
import Button from '../../elements/Button/Button';
import Password from '../../elements/Password/Password';
import { user } from '@enigma/core/services';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { show, hide } from '../../store/reducers/toast';
import { close, MessageBoxResponses } from '../../store/reducers/messageBox';
import Input from '../../elements/Input/Input';

function MessageBox() {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");
    const messageBox = useAppSelector(state => state.messageBox);
    const dispatch = useAppDispatch();

    const onReturn = (state: MessageBoxResponses) => {
        // check if user reauthentication was requested
        if (messageBox.type === 'authenticate') {
            if (state !== 'cancled') {
                if (!value || value === '') {
                    dispatch(show({
                        message: 'password can not be empty',
                        isError: true
                    }));
                    const timer = setTimeout(() => {
                        dispatch(hide());
                        clearTimeout(timer);
                    }, 3000);
                    return;
                }
                setLoading(true);
                // re-authenticate user
                user.reauthenticateWithCredential(user.email as string, value)
                    .then(_ => dispatch(close({ response: state })))
                    .catch(error => {
                        if (error.code === 'auth/wrong-password') {
                            dispatch(show({
                                message: 'your password was incorrect',
                                isError: true
                            }));
                            const timer = setTimeout(() => {
                                dispatch(hide());
                                clearTimeout(timer);
                            }, 3000);
                        }
                        else {
                            dispatch(show({
                                message: 'credentials could not be validated at this time',
                                isError: true
                            }));
                            dispatch(show({
                                message: 'your password was incorrect',
                                isError: true
                            }));
                        }
                        setLoading(false);
                    })
            } else dispatch(close({ response: state }));

        } else if (messageBox.type === 'prompt') {
            dispatch(close({ response: value || state }))
        } else dispatch(close({ response: state }));
    }

    const getContent = () => {
        if (messageBox.type === 'authenticate') {
            return (
                <div className={Stylesheet.Field}>
                    <h5>the operation requested is a security intensive task, for
                        security reasons please re-enter your current password.</h5>
                    <div className={Stylesheet.Input}>
                        <Password placeholder='enter current password'
                            onChange={value => setValue(value)} />
                    </div>
                </div>
            )
        } else if (messageBox.type === 'prompt') {
            return (
                <div className={Stylesheet.Field}>
                    <h5>{messageBox.prompt}</h5>
                    <div className={Stylesheet.Input}>
                        <Input placeholder='enter a value'
                            onChange={value => setValue(value)} />
                    </div>
                </div>
            )
        } else return <p>{messageBox.prompt}</p>;
    }

    const getControls = () => {
        if (messageBox.type === 'yes_no') {
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary
                            onClick={_ => onReturn(MessageBoxResponses.no)}>no</Button>
                    </div>
                    <div className={Stylesheet.Control}>
                        <Button primary
                            onClick={_ => onReturn(MessageBoxResponses.yes)}>yes</Button>
                    </div>
                </React.Fragment>
            )
        } else if (messageBox.type === 'ok_cancle') {
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary
                            onClick={_ => onReturn(MessageBoxResponses.ok)}>ok</Button>
                    </div>
                    <div className={Stylesheet.Control}>
                        <Button primary
                            onClick={_ => onReturn(MessageBoxResponses.cancle)}>cancle</Button>
                    </div>
                </React.Fragment>
            )
        } else if (messageBox.type === 'retry') {
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary
                            onClick={_ => onReturn(MessageBoxResponses.retry)}>retry</Button>
                    </div>
                </React.Fragment>
            )
        } else if (messageBox.type === 'approve_deny') {
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary
                            onClick={_ => onReturn(MessageBoxResponses.approve)}>approve</Button>
                    </div>
                    <div className={Stylesheet.Control}>
                        <Button primary
                            onClick={_ => onReturn(MessageBoxResponses.deny)}>deny</Button>
                    </div>
                </React.Fragment>
            )
        } else if (messageBox.type === 'authenticate') {
            // render user re-authentication controls
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary disabled={loading}
                            onClick={_ => onReturn(MessageBoxResponses.cancle)}>
                            cancle</Button>
                    </div>
                    <div className={Stylesheet.Control}>
                        <Button primary loading={loading}
                            onClick={_ => onReturn(MessageBoxResponses.authenticated)}>
                            authenticate</Button>
                    </div>
                </React.Fragment>
            )
        } else if (messageBox.type === 'prompt') {
            return (
                <React.Fragment>
                    <div className={Stylesheet.Control}>
                        <Button secondary disabled={loading}
                            onClick={_ => onReturn(MessageBoxResponses.cancle)}>
                            cancle</Button>
                    </div>
                    <div className={Stylesheet.Control}>
                        <Button primary loading={loading}
                            onClick={_ => onReturn(MessageBoxResponses.confirm)}>
                            confirm</Button>
                    </div>
                </React.Fragment>
            )
        } else return null;
    }

    return (
        <div className={Classnames(Stylesheet.Container, {
            [Stylesheet['show-container']]: messageBox.show,
            [Stylesheet['hide-container']]: !messageBox.show
        })}>
            <div className={Classnames(Stylesheet.Overlay, {
                [Stylesheet['show-overlay']]: messageBox.show,
                [Stylesheet['hide-overlay']]: !messageBox.show
            })}></div>
            <div className={Classnames(Stylesheet.MessageBox, {
                [Stylesheet['show-messageBox']]: messageBox.show,
                [Stylesheet['hide-messageBox']]: !messageBox.show
            })}>
                <div className={Stylesheet.Header}>
                    <div className={Stylesheet.Title}>
                        <h5>{messageBox?.title}</h5></div>
                </div>
                <div className={Stylesheet.Content}>
                    {getContent()}
                </div>
                <div className={Stylesheet.Controls}>
                    {getControls()}
                </div>
            </div>
        </div>
    )
}

export default MessageBox;