import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Stylesheet from './View.module.css';
import Logo from '../../assets/logo.png';
import { firestore } from '@enigma/core/services';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
    ServiceTextInterface,
    setServiceText,
    setServiceTexts,
    setSettings,
    setSources,
    setTemplate,
    SourceInterface
} from '../../store/reducers/editor';
import { setSettings as setPreviewerSettings } from '../../store/reducers/previewer';
import {
    ico_frown
} from '../../icon';
import Editor from '../../containers/Editor/Editor';
import Previewer from '../../containers/Previewer/Previewer';
import util from '@enigma/core/utilities';
import axios from 'axios';

const View = () => {
    const { templateID } = useParams();
    const userState = useAppSelector(state => state.users);
    const userMeta = useAppSelector(state => state.users);
    const isUnauthorized = !userMeta.isLoggedIn || userMeta.role === "user";
    const editorState = useAppSelector(state => state.editor);
    const previewer = useAppSelector(state => state.previewer);
    const isSynced = editorState.template?.id === templateID;
    const setEditor = useAppDispatch();
    const { search } = useLocation();
    const iframeRef: Array<React.RefObject<HTMLIFrameElement>> = [React.createRef(), React.createRef()];
    // identify ways to set active service text from available versions
    const ActiveServiceText = editorState.serviceText;

    const getFrameHeight = (frame: Document) => {
        return Math.max(
            frame.body.scrollHeight, frame.documentElement.scrollHeight,
            frame.body.offsetHeight, frame.documentElement.offsetHeight,
            frame.body.clientHeight, frame.documentElement.clientHeight
        )
    }

    const getScrollPercentage = (frameDoc: Document, frameWin: Window) => {
        // calculate scroll percentage
        var winheight = frameWin.innerHeight || (frameDoc.documentElement || frameDoc.body).clientHeight
        var docheight = getFrameHeight(frameDoc);
        var scrollTop = window.pageYOffset || (frameDoc.documentElement || frameDoc.body.parentNode || frameDoc.body).scrollTop
        var trackLength = docheight - winheight
        return Math.floor(scrollTop / trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
    }
    /**
     * Calculate ScrollY value based on active scroll position of active frame
     * @param frameDoc Mirror frame Document
     * @param frameWin Mirror frame Window
     * @param percentage Active frame scroll percentage
     */
    const getRelativeScrollY = (frameDoc: Document, frameWin: Window, percentage: number) => {
        var winheight = frameWin.innerHeight || (frameDoc.documentElement || frameDoc.body).clientHeight
        var docheight = getFrameHeight(frameDoc);
        var trackLength = docheight - winheight
        return trackLength * (percentage / 100);
    }

    const onScroll = (index: number) => {
        const currentFrame = iframeRef[index];
        const otherFrame = index === 0 ? iframeRef[1] : iframeRef[0];
        if (currentFrame && otherFrame
            && currentFrame.current?.contentWindow && otherFrame.current?.contentWindow
            && currentFrame.current?.contentDocument && otherFrame.current?.contentDocument) {
            // calculate scroll percantge for current frame
            const currentScrollPercentage = getScrollPercentage(currentFrame.current.contentDocument, currentFrame.current.contentWindow);
            const scrollY = getRelativeScrollY(otherFrame.current.contentDocument, otherFrame.current.contentWindow, currentScrollPercentage);
            otherFrame.current.contentWindow.scrollTo({
                top: scrollY,
                left: 0
            })
        }
    }
    // fetch all relevent assests
    const init = async () => {
        // fetch lastest service text for template
        if (!ActiveServiceText) {
            // service text set, direct link asumed
            const meta = search.replace('?', "")
                .replace("month=", "").replace("date=", "")
                .replace("year=", "").replace("type=", "")
                .replaceAll("lang=", "").replaceAll("jurisdiction=", "")
                .replaceAll("%26", "&").split("&");
            // verify the url provided all required metadata
            if (meta.length < 4) {
                setEditor(setServiceText(null));
                setEditor(setSettings({ namespace: "template" }))
            } else {
                // lookup service text
                await axios.get(`https://us-central1-typikon-1.cloudfunctions.net/parser?day=${meta[1]}&month=${meta[0]}&year=${meta[2]}`)
                    .then(res => {
                        if (!res.data && [...res.data].length === 0) {
                            setEditor(setServiceText(null));
                            setEditor(setSettings({ namespace: "template" }));
                        }
                        // sort services into collections
                        const services: Array<ServiceTextInterface> = res.data;
                        const defaultLang = meta[4].toUpperCase().split(',') || userState.languages || ["EN"];
                        const defaultJurisdiction = meta[5] || userState.jurisdiction || "AANA";
                        const service = services.find(
                            (val) => val.service_type === meta[3]
                                && defaultLang.find((lang) => lang === val.language)
                                && val.jurisdiction === defaultJurisdiction
                        );
                        if (service) {
                            setEditor(setServiceText(defaultLang.length === 2 ? null : service));
                            setEditor(setServiceTexts(services));
                            setEditor(setPreviewerSettings({ ...previewer.settings, language: defaultLang as any, jurisdiction: defaultJurisdiction as any }));
                        } else {
                            setEditor(setServiceText(services[0]));
                            setEditor(setServiceTexts(services))
                            setEditor(setSettings({ namespace: "template" }))
                        }
                    })
                    .catch(_ => { setEditor(setServiceText(null)); setEditor(setSettings({ namespace: "template" })) })
            }
        }
        if (ActiveServiceText && ActiveServiceText.service_type) {
            // refference to local instance
            const database = new util.database("orthodoxliturgikon");
            let sources = database.table('sources');
            // fetch all relevent sources and sync to device
            if ((await sources.where('services', "array-contains", ActiveServiceText.service_type).limit(1).get()).empty)
                await firestore.collection('sources')
                    .where('services', 'array-contains', ActiveServiceText.service_type)
                    .get().then(async snap => {
                        let collection: Array<SourceInterface> = [];
                        for (const source of snap.docs) {
                            collection.push(source.data() as any);
                            // save / update cached collection
                            await sources.document(source.id).set(source.data());
                        }
                        setEditor(setSources(collection));
                    })
        }
        // fetch service text template
        if (!isSynced) {
            // fetch template meta and evaluate privilages
            await firestore.collection('templates')
                .doc(templateID)
                .get().then(snap => {
                    if (snap.exists) {
                        setEditor(setTemplate({ ...snap.data(), id: snap.id }));
                    } else {
                        setEditor(setTemplate(undefined));
                    }
                })
        }
    }

    useEffect(() => {
        // initilize editor
        init().then(_ => setEditor(setSettings({ loadingEditor: false })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEditor(setSettings({ disableEditor: isUnauthorized }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnauthorized])

    if (editorState.settings.loadingEditor) {
        return (
            <div className={Stylesheet.Container}>
                <div className={Stylesheet.Loading}>
                    <img src={Logo} alt="loading" />
                </div>
            </div>
        )
    }

    if (!editorState.template && !isUnauthorized) {
        return (
            <div className={Stylesheet.Container}>
                <div className={Stylesheet.NotAuthorized}>
                    <h1>{ico_frown}</h1>
                    <h4>template not found or not authorized</h4>
                    <h5>the requested template either could not be found or
                        you do not have privileges to access the editor with the requested
                        template, please contact your adminstrator for further information.
                    </h5>
                </div>
            </div>
        )
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Dashboard}>
                {previewer.settings.language.map((value, index) => (
                    <Previewer lang={value} key={index + Math.random()} frameRef={iframeRef[index]} onScroll={() => onScroll(index)} />
                ))}
                <Editor />
            </div>

        </div>
    )
}

export default View;