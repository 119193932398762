import React from 'react'
import Stylesheet from './Popup.module.css';
import Classnames from 'classnames';
import Button from '../../elements/Button/Button';
import { ico_times } from '../../icon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { close } from '../../store/reducers/popup';

function Popup() {
    const popup = useAppSelector(state => state.popup);
    const dispatch = useAppDispatch();

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: popup.show,
        [Stylesheet['hide-container']]: !popup.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: popup.show,
        [Stylesheet['hide-overlay']]: !popup.show
    })
    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: popup.show,
        [Stylesheet['hide-popup']]: !popup.show
    })
    const icon = popup?.icon ? <h1>{popup.icon}</h1> : null;

    return (
        <div className={containerStyle}>
            <div className={overlayStyle}
                onClick={_ => dispatch(close())}></div>
            <div className={popupStyle}>
                {icon ? <div className={Stylesheet.Icon}>
                    {icon}</div> : null}
                <div className={Stylesheet.Header}>
                    <h5>{popup?.title}</h5>
                    <Button primary onClick={close}>
                        {ico_times}</Button>
                </div>
                <div className={Stylesheet.Content}>
                    {popup.children}</div>
            </div>
        </div>
    )
}

export default Popup;