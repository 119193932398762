import util from '@enigma/core/utilities';
import React, { useEffect, useState } from 'react';
import { ico_arrow_left, ico_arrow_right } from '../../icon';
import Button from '../Button/Button';
import Stylesheet from './Calendar.module.css';

interface CalendarProps {
    defaultValue?: number,
    onChange?: (date: number) => void
}

const Calendar = (props: CalendarProps) => {
    const [selectedDate, setSelectedDate] = useState(props.defaultValue || Date.now());
    const currentDate = new util.cdate(selectedDate);

    const onChange = (date: number) => {
        const newDate = new Date(`${currentDate.getMonth()}/${date}/${currentDate.getYear()}`).getTime();
        if (props.onChange) props.onChange(newDate);
        setSelectedDate(newDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { onChange(parseInt(new util.cdate(selectedDate).getDate().toString())) }, [])

    const prefixDates = () => {
        let collection = [];
        const dateDay = new util.cdate(`${currentDate.getMonth()}/1/${currentDate.getYear()}`).getCalendarDay(true);
        const index = util.date.getCalendarDays(true).findIndex((current) => current === dateDay);
        const lastMonth = new util.cdate(`${currentDate.getMonth()}/1/${currentDate.getYear()}`);
        lastMonth.decrementMonth(1);
        const lastMonthDays = new util.cdate(`${lastMonth.getMonth()}/1/${currentDate.getYear()}`).getDaysInMonth;
        for (let day = lastMonthDays - (index - 1); day <= lastMonthDays; day++)
            collection.push(<p key={day + Math.random()} className={Stylesheet.Prefix}>{day}</p>)
        return collection;
    }

    const sufixDates = () => {
        let collection = [];
        const lastDayOfMonth = currentDate.getDaysInMonth;
        const dayLastDayOfMonth = new util.cdate(`${currentDate.getMonth()}/${lastDayOfMonth}/${currentDate.getYear()}`).getCalendarDay(true);
        const lastDayOfMonthIndex = util.date.getCalendarDays(true).findIndex((value) => value === dayLastDayOfMonth);
        for (let day = 1; day <= 7 - (lastDayOfMonthIndex + 1); day++)
            collection.push(<p key={day + Math.random()} className={Stylesheet.Prefix}>{day}</p>)
        return collection;
    }

    const dates = () => {
        let collection = [];
        for (let date = 1; date <= currentDate.getDaysInMonth; date++)
            collection.push(
                <p key={date + Math.random()}
                    className={new util.cdate(selectedDate).getDate("numeric") === date ? Stylesheet.Active : undefined}
                    onClick={_ => onChange(date)}>{date}
                </p>
            )
        return collection;
    }

    const days = () => {
        let collection = [];
        for (const day of util.date.getCalendarDays(true))
            collection.push(<p key={collection.length + Math.random()}>{day}</p>)
        return collection;
    }

    const nextMonth = () => {
        currentDate.incrementMonth(1);
        setSelectedDate(currentDate.dateObject.getTime());
    }

    const prevMonth = () => {
        currentDate.decrementMonth(1);
        setSelectedDate(currentDate.dateObject.getTime());
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Header}>
                <Button ariaLabel="Previous Month" icon secondary onClick={prevMonth}>{ico_arrow_left}</Button>
                <p>{new util.cdate(selectedDate).getCalendarMonth()}&nbsp;{new Date(selectedDate).getFullYear()}</p>
                <Button ariaLabel="Next Month" icon secondary onClick={nextMonth}>{ico_arrow_right}</Button>
            </div>
            <div className={Stylesheet.Calendar}>
                {days().map((value) => (value))}
                {prefixDates().map((value) => (value))}
                {dates().map((value) => (value))}
                {sufixDates().map((value) => (value))}
            </div>
        </div>
    )
}

export default Calendar;