import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index';

// Define a type for the slice state
export type ServiceType = "Vespers" | "Orthros" | "Divine Liturgy" | "Vesperal Liturgy" | "Paraklesis" | "Holy Week" | "Pascha" | "Other";
export type TemplateLanguage = "EN" | "GR" | "AR";
export type TemplateJurisdiction = "AANA" | "GOA" | "OCA";

interface EntityInterface {
    jurisdiction: string,
    display_name: string,
    website: string,
    address: string,
    image: string,
    verified: string
}

interface FeastInterface {
    name: string,
    short_name: string,
    start_month: number,
    start_date: number,
    end_month: number,
    end_date: number,
    day_of_week: number,
    rank: number,
    effective_rank: number,
    days_of_preparation: string,
    days_from_pascha: string,
    weeks_from_pascha: string,
    length: number,
    fixed: boolean,
    sourceText: string,
    sourceStartPage: number,
    sourceEndPage: number,
    dismissal_name: string,
    creator: string,
    jurisdiction: string,
    service_type: string
}

interface ErotologionInterface {
    name: string,
    short_name: string,
    dismissal_name: string,
    month: number,
    date: number,
    pronounciation: string,
    display_level: number,
    rank: number,
    creator: string,
    jurisdiction: string
}

export interface SourceInterface {
    id: string,
    name: string,
    month: number,
    date: number,
    occasion: string,
    version: number,
    translation: string,
    language: Array<string>,
    sourceType: Array<string>,
    services: Array<string>,
    sourceStartPage: number,
    sourceEndPage: number,
    shortName: string,
    section: string,
    subsection: string,
    sectionTitle: string,
    subsectionTitle: string,
    sectionOrderNumber: number,
    subsectionOrderNumber: number,
    verseText: string,
    text: string,
    tone: number,
    eothinon: number,
    specialMelody: string,
    creator: string,
    latest: boolean,
    music: string,
    jurisdiction: string,
    tags: Array<string>
}

export interface ServiceTextInterface {
    id?: string,
    template?: string,
    feast?: string,
    creator?: string,
    version?: number,
    month?: number,
    date?: number,
    year?: number,
    language?: string,
    text?: string,
    jurisdiction?: string,
    service_type?: string,
    pdfURL?: string,
    rtfURL?: string
}

interface MusicInterface {
    name: string,
    notation: string,
    occasion: string,
    arragementType: string,
    URL: string,
    composer: string,
    languages: Array<string>,
    tone: number
}

export interface TemplateInterface {
    id?: string,
    name?: string,
    startMonth?: number,
    startDate?: number,
    endMonth?: number,
    endDate?: number,
    daysFromPascha?: number | string,
    endDaysFromPascha?: number | string,
    fixedDate?: boolean,
    dayOfWeek?: string,
    creator?: string,
    commitBy?: string,
    commitRole?: string,
    version?: number,
    language?: TemplateLanguage,
    text?: string,
    remark?: string,
    rank?: number,
    jurisdiction?: TemplateJurisdiction,
    verified?: boolean,
    timestamp?: string,
    serviceType?: ServiceType,
    collection?: string
}

interface EditorSettings {
    disableHTML?: boolean,
    disableCSS?: boolean,
    disableJS?: boolean,
    disableEditor?: boolean,
    tab?: "html" | "css" | "javascript",
    namespace?: "template" | "service-text",
    hidePreviwer?: boolean,
    hideEditor?: boolean,
    realtimeUpdates?: boolean,
    loadingEditor?: boolean,
    loadingHTML?: boolean,
    loadingCSS?: boolean,
    loadingJS?: boolean
}

export interface EditorState {
    template?: TemplateInterface | null,
    entities?: EntityInterface,
    feasts?: FeastInterface,
    erotologion?: ErotologionInterface,
    source?: Array<SourceInterface>,
    serviceTexts?: Array<ServiceTextInterface> | null,
    serviceText?: ServiceTextInterface,
    music?: MusicInterface,
    settings: EditorSettings
}

// Define the initial state using that type
const initialState: EditorState = {
    settings: {
        hideEditor: true,
        hidePreviwer: false,
        disableEditor: true,
        tab: "html",
        namespace: "service-text",
        loadingEditor: true,
        realtimeUpdates: true,
        disableJS: true
    }
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        togglePreviwer: (state) => {
            return { ...state, settings: { ...state.settings, hidePreviwer: state.settings.hidePreviwer ? false : true } };
        },
        toggleEditor: (state) => {
            return { ...state, settings: { ...state.settings, hideEditor: state.settings.hideEditor ? false : true } };
        },
        setTags: (state, action: PayloadAction<string>) => {
            return { ...state, template: { ...state.template, text: action.payload } };
        },
        setTemplate: (state, action: PayloadAction<TemplateInterface | null | undefined>) => {
            if (action.payload)
                return { ...state, template: { ...state.template, ...action.payload } };
            return { ...state, template: undefined };
        },
        setSources: (state, action: PayloadAction<Array<SourceInterface>>) => {
            return { ...state, source: action.payload };
        },
        setSettings: (state, action: PayloadAction<EditorSettings>) => {
            return { ...state, settings: { ...state.settings, ...action.payload } };
        },
        setServiceTexts: (state, action: PayloadAction<Array<ServiceTextInterface> | undefined | null>) => {
            if (action.payload)
                return { ...state, serviceTexts: action.payload };
            return { ...state, serviceTexts: undefined };
        },
        setServiceText: (state, action: PayloadAction<ServiceTextInterface | undefined | null>) => {
            if (action.payload)
                return { ...state, serviceText: { ...state.serviceText, ...action.payload } };
            return { ...state, serviceText: undefined };
        },
        setTab: (state, action: PayloadAction<"html" | "css" | "javascript">) => {
            return { ...state, settings: { ...state.settings, tab: action.payload } };
        }
    }
})

export const {
    togglePreviwer,
    toggleEditor,
    setTags,
    setTemplate,
    setSources,
    setServiceText,
    setServiceTexts,
    setSettings,
    setTab
} = editorSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.users;

export default editorSlice.reducer