import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Stylesheet from './Auth.module.css';
import Classnames from 'classnames';
import Button from '../../elements/Button/Button';
import { ico_facebook, ico_google, ico_times } from '../../icon';
import { close } from '../../store/reducers/auth';
import { show, hide } from '../../store/reducers/toast';
import Email from '../../elements/Email/Email';
import Password from '../../elements/Password/Password';
import Input from '../../elements/Input/Input';
import { firestore, user } from '@enigma/core/services';
import { login, logout, UserState } from '../../store/reducers/users';
import toggleToast from '../../store/actions/toast.actions';

function Auth() {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const email_password = async () => {
        setLoading(true);

        if (auth.type === "sign-up") {
            //verifiy email address
            if (!await user.isEmailAvailable(email, ["password", "facebook.com", "google.com"])) {
                dispatch(show({
                    isError: true,
                    message: "email address is already in use.",
                }));
                const timer = setTimeout(() => {
                    dispatch(hide());
                    clearTimeout(timer);
                }, 3000)
                setLoading(false);
                return;
            }
            // verify display name
            if (firstName === "" || !firstName || lastName === "" || !lastName) {
                dispatch(show({
                    isError: true,
                    message: "please enter your first and last name",
                }));
                const timer = setTimeout(() => {
                    dispatch(hide());
                    clearTimeout(timer);
                }, 3000)
                setLoading(false);
                return;
            }
            // create user
            await user.createCustom(email, password, {
                email: email,
                first_name: firstName,
                last_name: lastName,
                displayName: firstName + " " + lastName,
                auth_type: "email_password",
                role: "user"
            }).then(_ => {
                user.updateDisplayName(firstName + " " + lastName);
                setLoading(false);
                dispatch(close());
            })

        } else {
            await user.signInWithEmailAndPassword(email, password)
                .then(_ => {
                    setLoading(false);
                    dispatch(close())
                })
                .catch(error => {
                    dispatch(show({
                        isError: true,
                        message: "log in failed, make sure the email and password is correct",
                    }));
                    const timer = setTimeout(() => {
                        dispatch(hide());
                        clearTimeout(timer);
                    }, 3000)
                    setLoading(false);
                })
        }
    }

    const providerRegistration = async (credentials: firebase.default.auth.UserCredential) => {
        setLoading(true);
        if (auth.type === "sign-up") {
            const userMeta = credentials.user as firebase.default.User;
            const userInfo = credentials.additionalUserInfo as firebase.default.auth.AdditionalUserInfo;
            const emailAssociation = userInfo.providerId === "google.com" ? "facebook.com" : "google.com";
            // check if the email address is associated with another provider
            if (!await user.isEmailAvailable(userMeta.email as string, [emailAssociation, "password"])) {
                // the email address associated with the provider is already registered with a secondary provider
                // identify which provider is the primary account holder
                const profile: UserState = await user.getProfile();
                if (profile.providerID !== userInfo.providerId) {
                    // current provider is not the primary account holder
                    toggleToast({
                        message: `email is already in use with a ${profile.providerID} account, please sign-in with said provider.`,
                        isError: true
                    });
                    setLoading(false);
                    return;
                } else {
                    // provider is the primary account holder
                    // login user
                    // update app auth state
                    dispatch(login({ ...profile, isLoggedIn: true }));
                }
            } else {
                // new registration with provider
                // user profile payload
                const profile: UserState = {
                    email: userMeta.email as string,
                    first_name: userMeta.displayName?.split(' ')[0],
                    last_name: userMeta.displayName?.split(' ')[1],
                    displayName: userMeta.displayName as string,
                    providerID: userInfo.providerId,
                    photoURL: userMeta.photoURL as string,
                    phone: userMeta.phoneNumber || null,
                    role: "user",
                    providerMeta: userInfo.profile || null,
                    photoURLSmall: await user.getPhotoURL(96, 96, userMeta.uid) as string,
                    photoURLLarge: await user.getPhotoURL(700, 700) as string,
                }
                // create user profile
                await user.setProfile(profile);
                // update app auth state
                dispatch(login({ ...profile, isLoggedIn: true }));
            }
        } else {
            // fetch user profile
            firestore.collection('users').doc(credentials.user?.uid).get()
                .then(doc => {
                    if (doc.data()?.disabled) {
                        toggleToast({
                            message: "your account as been temporarly disabled, please contact your administrator.",
                            isError: true
                        })
                        dispatch(logout())
                        return;
                    }
                    dispatch(login({ ...doc.data(), isLoggedIn: true }))
                })
        }
        user.envokeAuthEvents();
        // close popup
        setLoading(false);
        dispatch(close())
    }

    const facebook = async () => {
        user.revokeAuthEvents();
        user.createWithProvider("facebook", false)
            .then(async result => providerRegistration(result))
            .catch(error => {
                toggleToast({
                    message: "email is already in use with a google.com account, please sign-in with that provider.",
                    isError: true
                });
            })
    }
    const google = async () => {
        user.revokeAuthEvents();
        user.createWithProvider("google", false)
            .then(result => providerRegistration(result))
            .catch(error => {
                toggleToast({
                    message: "email is already in use with a facebook.com account, please sign-in with that provider.",
                    isError: true
                });
            })
    }

    const form = () => {
        if (auth.type === undefined) return null;
        if (auth.type === "sign-up") {
            // render sign-up form controls
            return (
                <form className={Stylesheet.Form} onSubmit={event => {
                    event.preventDefault(); email_password();
                }}>
                    <div className={Stylesheet.Field}>
                        <Input placeholder="your name i.e adam smith" disabled={loading} required
                            onChange={value => { setFirstName(value.split(' ')[0]); setLastName(value.split(' ')[1]); }} />
                    </div>
                    <div className={Stylesheet.Field}>
                        <Email onChange={value => setEmail(value)} required disabled={loading} />
                    </div>
                    <div className={Stylesheet.Field}>
                        <Password onChange={value => setPassword(value)} required disabled={loading} />
                    </div>
                    <div className={Stylesheet.Controls}>
                        <Button type="submit" primary loading={loading}>sign up</Button>
                    </div>
                </form>
            )
        } else {
            // render sign-in form controls
            return (
                <form className={Stylesheet.Form} onSubmit={event => {
                    event.preventDefault(); email_password();
                }}>
                    <div className={Stylesheet.Field}>
                        <Email onChange={value => setEmail(value)} required disabled={loading} />
                    </div>
                    <div className={Stylesheet.Field}>
                        <Password onChange={value => setPassword(value)} required disabled={loading} />
                    </div>
                    <div className={Stylesheet.Controls}>
                        <Button type="submit" primary loading={loading}>log in</Button>
                    </div>
                </form>
            )
        }
    }

    const providers = () => {
        if (!auth.providers || auth.providers.length === 0) return null;
        const providerCollection: Array<JSX.Element> = [];
        for (const provider of auth.providers) {
            // render each allowed sign-in provider
            if (provider === "facebook") {
                providerCollection.push(
                    <Button disabled={loading} onClick={_ => facebook()}>{ico_facebook}&emsp;
                        {auth.type === "sign-in"
                            ? <p>sign in with facebook</p>
                            : <p>sign up with facebook</p>}</Button>
                )
            } else if (provider === "google") {
                providerCollection.push(
                    <Button disabled={loading} onClick={_ => google()}>{ico_google}&emsp;
                        {auth.type === "sign-in"
                            ? <p>sign in with google</p>
                            : <p>sign up with google</p>}</Button>
                )
            }
        }
        return <div className={Stylesheet.Providers}>
            {providerCollection.map((value, index) => (
                <div className={Stylesheet.Provider}
                    key={index + Math.random()}>
                    {value}
                </div>
            ))}
        </div>
    }

    const containerStyle = Classnames(
        Stylesheet.Container, {
        [Stylesheet['show-container']]: auth.show,
        [Stylesheet['hide-container']]: !auth.show
    })
    const overlayStyle = Classnames(
        Stylesheet.Overlay, {
        [Stylesheet['show-overlay']]: auth.show,
        [Stylesheet['hide-overlay']]: !auth.show
    })
    const popupStyle = Classnames(
        Stylesheet.Popup, {
        [Stylesheet['show-popup']]: auth.show,
        [Stylesheet['hide-popup']]: !auth.show
    })
    return (
        <div className={containerStyle}>
            <div className={overlayStyle}
                onClick={_ => !loading && dispatch(close())}></div>
            <div className={popupStyle}>
                <div className={Stylesheet.Header}>
                    <h5>{auth?.title}</h5>
                    <Button primary onClick={_ => !loading && dispatch(close())}>
                        {ico_times}</Button>
                </div>
                {form()}
                {providers()}
            </div>
        </div>
    )
}

export default Auth;