import { firestore } from '@enigma/core/services';
import React from 'react';
import { formatAddress } from '../../elements/Address/Address';
import Button from '../../elements/Button/Button';
import Image from '../../elements/Image/Image';
import { ico_trash, ico_user, ico_user_edit, ico_user_slash, ico_user_tie } from '../../icon';
import { toggleMessageBox } from '../../store/actions/messageBox.actions';
import toggleToast from '../../store/actions/toast.actions';
import { useAppSelector } from '../../store/hooks';
import { UserState } from '../../store/reducers/users';
import Stylesheet from './User.module.css';
import DefaultUser from '../../assets/user-default.jpg';
import { MessageBoxTypes } from '../../store/reducers/messageBox';

const User = (props: UserState) => {
    const userMeta = useAppSelector(state => state.users);

    const validatePrevilages = async (role?: "user" | "administrator" | "reviewer") => {
        if (role && props.role === role) return false;
        if ((props.role === "administrator" || role === "administrator") && userMeta.role !== "root") {
            await toggleMessageBox({
                type: MessageBoxTypes.ok_cancle,
                title: `elevated previlages required`,
                prompt: `This operation requires an account with root privilages, please contact your administrator for further information.`
            });
            return false;
        }
        return true;
    }

    const updateRole = async (role: "user" | "administrator" | "reviewer") => {
        if (!await validatePrevilages(role)) return;
        // request confirmation
        const response = await toggleMessageBox({
            type: MessageBoxTypes.yes_no,
            title: `confirm role update`,
            prompt: `This operation will update ${props.displayName}'s role from '${props.role}' to '${role}'. Are you sure you wish to perform this operation ?`
        });
        if (response === "yes") {
            firestore.collection('users').doc(props.id).update({ role: role })
                .then(_ => {
                    toggleToast({
                        message: `${props.displayName}'s role successfully changed to ${role}`,
                        isError: false
                    })
                }, _ => {
                    toggleToast({
                        message: `${props.displayName}'s role couldn't be updated, please try again later`,
                        isError: true
                    })
                })
        }

    }
    const remove = async () => {
        if (!await validatePrevilages()) return;
        // request confirmation
        const response = await toggleMessageBox({
            type: MessageBoxTypes.yes_no,
            title: `confirm account removal`,
            prompt: `This operation will remove ${props.displayName}, once the account is removed the user will no longer be able to login to the site, but they may re-create an account if they wish. Are you sure you wish to perform this operation ?`
        });
        if (response === "yes") {
            firestore.collection('users').doc(props.id).delete()
                .then(_ => {
                    toggleToast({
                        message: `${props.displayName} successfully removed`,
                        isError: false
                    })
                }, _ => {
                    toggleToast({
                        message: `${props.displayName} couldn't be removed, please try again later`,
                        isError: true
                    })
                })
        }

    }

    const disable = async () => {
        if (!await validatePrevilages()) return;
        const action: "disable" | "enable" = props.disabled ? "enable" : "disable";
        const response = await toggleMessageBox({
            type: MessageBoxTypes.yes_no,
            title: `confirm ${action}`,
            prompt: `Confirm ${action} of user '${props.displayName}', once the account is ${action}d the user will ${action === "disable" ? "no longer be" : "be"} able to login to the site until their account is ${action === "disable" ? "re-enabled" : "re-disabled"}. Are you sure you wish to perform this operation ?`
        });
        if (response === "yes") {
            firestore.collection('users').doc(props.id).update({ disabled: action === "disable" ? true : false })
                .then(_ => {
                    toggleToast({
                        message: `${props.displayName} successfully ${action}d`,
                        isError: false
                    })
                }, _ => {
                    toggleToast({
                        message: `${props.displayName} couldn't be ${action}, please try again later`,
                        isError: true
                    })
                })
        }
    }

    return (
        <div className={Stylesheet.Container}>
            <div className={Stylesheet.Photo}>
                {props.photoURL
                    ? <Image src={props.photoURL} alt={props.displayName as string} />
                    : <Image src={DefaultUser} alt='user profile default' />}
            </div>
            <div className={Stylesheet.Metadata}>
                <h5>{props.displayName}</h5>
                <h6>{props.email}</h6>
                <h6>{props.address && formatAddress(props.address)}</h6>
            </div>
            <div className={Stylesheet.Controls}>
                <div className={Stylesheet.Roles}>
                    <Button icon primary={props.role === "user"} onClick={_ => updateRole("user")}>{ico_user}</Button>
                    <Button icon primary={props.role === "reviewer"} onClick={_ => updateRole("reviewer")}>{ico_user_edit}</Button>
                    <Button icon primary={props.role === "administrator"} onClick={_ => updateRole("administrator")}>{ico_user_tie}</Button>
                </div>
                <div className={Stylesheet.Account}>
                    <Button icon error primary={props.role === "user"} onClick={remove}>{ico_trash}</Button>
                    <Button icon primary={props?.disabled} onClick={disable}>{ico_user_slash}</Button>
                </div>
            </div>
        </div>
    )
}

export default User;