import React, { useEffect, useState } from 'react';
import Stylesheet from './DropMenu.module.css';
import Classnames from 'classnames';
import { ico_caret_down } from '../../icon';

interface DropMenuProps {
    loading?: boolean,
    textProp?: string,
    values: Array<any | string>,
    defaultValue?: string,
    onChange?: (value: any | string) => void,
    disabled?: boolean,
    placeholder?: string,
    icon?: JSX.Element
}

const DropMenu = (props: DropMenuProps) => {
    const getValue = (index: number) => {
        if (props?.loading) return "loading..";
        if (props?.textProp)
            return props.values[index][props.textProp]
        return props.values[index];
    }
    const [active, setActive] = useState(false);
    const [value, setValue] = useState<string>(props?.defaultValue || "")
    useEffect(() => {
        setValue(props?.defaultValue || "")
    }, [props])
    const onChange = (index: number) => {
        setValue(getValue(index));
        setActive(false);
        if (props?.onChange) props.onChange(props.values[index])
    }

    return (
        <div className={Classnames(
            Stylesheet.Container, {
            [Stylesheet['Active']]: active,
            [Stylesheet['Loading']]: props?.loading || false,
            [Stylesheet['Disabled']]: props?.disabled || false
        })}>
            <div className={Stylesheet.Overlay}
                onClick={_ => setActive(false)}></div>
            <div className={Stylesheet.Title}
                onClick={_ => !props?.disabled
                    && setActive(active ? false : true)}>
                {props?.icon
                    ? <h5 className={Stylesheet.Icon}>{props.icon}</h5>
                    : null}
                <h5>{value || props?.placeholder || "select an option"}</h5>
                <h4>{ico_caret_down}</h4>
            </div>
            <div className={Stylesheet.Menu}>{
                props.values.map((value, index) => (
                    <h5 key={index} onClick={_ => onChange(index)}>
                        {getValue(index)}</h5>))}
            </div>
        </div>
    )
}

export default DropMenu;