import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import core from '@enigma/core';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// initilize firebase services
core.init({
  apiKey: "AIzaSyAEexegzc9AbBhYhXgtARY9e5TChVfOVRY",
  authDomain: "typikon-1.firebaseapp.com",
  databaseURL: "https://typikon-1.firebaseio.com",
  projectId: "typikon-1",
  storageBucket: "typikon-1.appspot.com",
  messagingSenderId: "658738079990",
  appId: "1:658738079990:web:589f710a3ac15263f1e4d7",
  measurementId: "G-23MRMZYK0Q"
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
