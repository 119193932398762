import React from 'react';
import { formatAddress } from '../../../elements/Address/Address';
import { useAppSelector } from '../../../store/hooks';
import Stylesheet from './About.module.css';


const About = () => {
    const userMeta = useAppSelector(state => state.users);

    return (
        <div className={Stylesheet.Container}>
            <h5>basic information</h5>
            <p>Phone: {userMeta.phone || "not provided"}</p>
            <p>address: {userMeta.address ? formatAddress(userMeta.address) : "not provided"}</p>
            <p>E-mail: {userMeta?.email}</p>
            <p>birthday: {userMeta.birthday || "not provided"}</p>
            <p>role: {userMeta.role}</p>
            <p>parish: {userMeta.parish || "not provided"}</p>
        </div>
    )
}

export default About;