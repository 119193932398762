import util from '@enigma/core/utilities';
import React, { useEffect, useState } from 'react';
import Source from '../../../components/Source/Source';
import Search from '../../../elements/Search/Search';
import { useAppSelector } from '../../../store/hooks';
import { SourceInterface } from '../../../store/reducers/editor';
import Stylesheet from './Sources.module.css';


const Sources = () => {
    const serviceText = useAppSelector(state => state.editor.serviceText);
    const activeSources = useAppSelector(state => state.previewer.activeSources);
    const [collection, setCollection] = useState<Array<SourceInterface>>([]);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<Array<SourceInterface>>();
    // refference to local instance
    const database = new util.database("orthodoxliturgikon");

    useEffect(() => {
        if (!serviceText || !serviceText.service_type) return;
        let sources = database.table('sources');
        sources.where('services', "array-contains", serviceText.service_type)
            .get().then(snap => {
                let collection: Array<SourceInterface> = [];
                for (const source of snap.getAll())
                    collection.push({ ...source.data, id: source.id })
                setCollection(collection);
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const search = (term: string) => {
        if (!serviceText || !serviceText.service_type) return;
        if (term === "") setResult(undefined);
        else {
            let sources = database.table('sources');
            sources.where('services', "array-contains", serviceText.service_type)
                .and("shortName", "like", term)
                .get().then(snap => {
                    let collection: Array<SourceInterface> = [];
                    for (const source of snap.getAll())
                        collection.push({ ...source.data, id: source.id })
                    setResult(collection);
                })
        }
    }

    const content = () => {
        if (!serviceText) {
            return (
                <div className={Stylesheet.Container}>
                    <div className={Stylesheet.NoActiveServiceText}>
                        <h3>no active service text</h3>
                        <p>there is currently no active service-text selected, please select a service-text and try again</p>
                    </div>
                </div>
            )
        }

        if (collection.length === 0 && !loading) {
            return (
                <div className={Stylesheet.Container}>
                    <div className={Stylesheet.NoSources}>
                        <h3>no sources found for service type '{serviceText.service_type}'</h3>
                        <p>the service type didnt match any listed sources, create a new source to use with the service-text</p>
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                <div className={Stylesheet.Header}>
                    <div className={Stylesheet.Search}>
                        <Search onChange={search} placeholder="search source name" />
                    </div>
                    {/* <Button>create new source for {serviceText?.service_type|| ""}</Button> */}
                </div>
                <div className={Stylesheet.ActiveSources}>
                    <p>active sources</p>
                    {activeSources && activeSources.length > 0
                        ? activeSources.map((source, index) =>
                        (<Source
                            active={true}
                            key={index + Math.random()} {...source} />))
                        : <p className={Stylesheet.Prompt}>no active sources on current service-text</p>}
                </div>
                <div className={Stylesheet.AvailableSources}>
                    <p>available sources for {serviceText?.service_type|| ""}</p>
                    {(result || collection)?.map((source, index) =>
                    (<Source
                        active={activeSources?.find((value) => value.id === source.id) ? true : false}
                        key={index + Math.random()} {...source} />))}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={Stylesheet.Container}>
            <h2>available sources</h2>
            {content()}
        </div>
    )
}

export default Sources;