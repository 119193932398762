import React, { useEffect } from 'react';
import Stylesheet from './App.module.css';
import { user } from '@enigma/core/services';
import { login, logout, UserState } from '../../store/reducers/users';
import { show, hide } from '../../store/reducers/toast';
import { useDispatch } from 'react-redux';
import Header from '../Header/header';
import Popup from '../../components/Popup/Popup';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/toast';
import Auth from '../../components/Auth/Auth';
import MessageBox from '../../components/MessageBox/MessageBox';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import Profile from '../../pages/Profile/Profile';
import Home from '../../pages/Home/Home';
import toggleToast from '../../store/actions/toast.actions';
import Editor from '../../pages/View/View';
import util from '@enigma/core/utilities';

function App() {
  // initilize local instances
  useEffect(() => initLocalInstances(), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => user.onAuthChange(onAppAuthChange), [])
  // User slice dispatcher
  const dispatch = useDispatch();

  const initLocalInstances = () => {
    const database = new util.database("orthodoxliturgikon");
    let sources = database.table('sources');
    // create local instance if not already created
    sources.exists().then(result => {
      if (result) sources.clear();
      else sources.create("id", false, false);
    })
  }

  const onAppAuthChange = async (authUser: firebase.default.User | null) => {
    // update user context
    if (authUser) {
      let profile: UserState = await user.getProfile();
      if (!profile) {
        // user might have not completed the registration process correctly,
        // remove user profile and inform user to re-create account
        await user.delete();
        // inform user
        toggleToast({ message: "your signup was interuppted, kindly re-create your account", isError: true })
        dispatch(logout());
        return;
      }
      if (profile?.disabled) {
        toggleToast({
          message: "your account as been temporarly disabled, please contact your administrator.",
          isError: true
        })
        user.signOut()
        return;
      }
      // update app auth state
      dispatch(login({ ...profile, isLoggedIn: true }));
      if (!user.isEmailVerified && !profile.providerMeta?.providerID) {
        user.sendEmailVerification()
          .then(_ => dispatch(show({
            message: "your email has not been verified yet, a verification email has been sent out to you're email address.",
            isError: true
          })))
        const timer = setTimeout(() => {
          dispatch(hide());
          clearTimeout(timer);
        }, 3000)
      }

    } else dispatch(logout());
  }

  return (
    <div className={Stylesheet.App}>
      <Toast />
      <Modal />
      <Popup />
      <MessageBox />
      <Auth />
      <Router>
        <Header />
        <div className={Stylesheet.Dashboard}>
          <Routes>
            <Route path='/profile/:tab' element={<Profile />} />
            <Route path='/view/:templateID' element={<Editor />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
        <small className={Stylesheet.Copyright}>&copy; 2022 orthodox liturgikon</small>
      </Router>
    </div>
  );
}

export default App;
